import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../..';
import {
  MY_CASES_ADD_EMAIL,
  MY_CASES_ADD_MESSAGE,
  MY_CASES_CLOSE_CONVERSATION,
  MY_CASES_SET_CLIENT_TYPING_TEXT,
  MY_CASES_UPDATE_CASE,
} from '../../store/constants';
import { io } from 'socket.io-client';
import { getHostName } from '../../../../../../../shared/http/helpers';

interface Props {
  userReducer: any;
}

const SOCKET_URL = `${getHostName()}:4002/ChatWebsocketModule/TwilioChatWebsocket/v1.0`;

const MyCasesWebSockets: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;

  const { state, dispatch } = useContext(MyCasesContext);
  const [socket, setSocket] = useState<any>(undefined);

  let caseIdRef: any = useRef(undefined);
  let feedContainerRef: any = useRef(null);

  // Initialize WebSockets
  useEffect(() => {
    const s = io(SOCKET_URL, {
      path: '/ws/socket.io',
      query: {
        clientId: userReducer.user?.id,
      },
    });

    if (s) {
      setSocket(s);
    }
  }, []);

  useEffect(() => {
    if (socket) {
      // Connect to WS, handle global events and join room for global events
      socket.on('connect', () =>
        console.log('%cdebug: [WS] [My Cases] Connected to WebSockets service', 'color:#bada55'),
      );
      socket?.on(`case-event-${userReducer.user.id}`, handleIncomingGlobalEvent);
      socket?.emit('joinRoom', userReducer.user.id);
    }

    // Websocket unmount -> disconnect and leave rooms
    return () => {
      if (socket) {
        console.log('%cdebug: [WS] [My Cases] Disconnected from WebSockets service', 'color:red');

        if (caseIdRef.current) {
          socket?.emit('leaveRoom', caseIdRef.current);
          socket?.off(`web-chat-input-${caseIdRef.current}`, handleClientTypingEvent);
          socket?.off(`case-event-${caseIdRef.current}`, handleIncomingEvent);
        }

        socket?.emit('leaveRoom', userReducer.user.id);
        socket?.off(`case-event-${userReducer.user.id}`, handleIncomingGlobalEvent);
        socket?.disconnect();
      }
    };
  }, [socket]);

  useEffect(() => {
    // CHANGED CASE -> Remove old / add new listeners
    if (state.selectedCase?.id !== caseIdRef.current && caseIdRef.current) {
      socket?.emit('leaveRoom', caseIdRef.current);
      socket?.off(`case-event-${caseIdRef.current}`);
      socket?.off(`web-chat-input-${caseIdRef.current}`);

      socket?.emit('joinRoom', state.selectedCase?.id);
      socket?.on(`case-event-${state.selectedCase?.id}`, handleIncomingEvent);
      socket?.on(`web-chat-input-${state.selectedCase?.id}`, handleClientTypingEvent);
    }
    // CHANGED CASE -> No previous case / add new listeners
    else if (state.selectedCase?.id !== caseIdRef.current && !caseIdRef.current) {
      socket?.emit('joinRoom', state.selectedCase?.id);
      socket?.on(`case-event-${state.selectedCase?.id}`, handleIncomingEvent);
      socket?.on(`web-chat-input-${state.selectedCase?.id}`, handleClientTypingEvent);
    }
  }, [state.selectedCase, caseIdRef, socket]);

  // Issue typing event when agent is typing...
  useEffect(() => {
    if (state.agentInputText.length > 0) {
      socket?.emit(`web-chat-input`, {
        caseId: state.selectedCase?.id,
        conversationId: state.selectedCaseConversation?.id,
        message: state.agentInputText,
        sender: 'AGENT',
      });
    }
  }, [state.agentInputText, socket, state.selectedCase]);

  // Update Selected Case Container Refs for use in events
  useEffect(() => {
    if (state.selectedCase?.id !== caseIdRef.current) {
      caseIdRef.current = state.selectedCase?.id;
    }
  }, [state.selectedCase, caseIdRef]);

  // Update Feed Container Refs for use in events
  useEffect(() => {
    if (
      state.feedContainerRef?.current?.scrollHeight! !== feedContainerRef?.current?.scrollHeight
    ) {
      feedContainerRef.current = state.feedContainerRef?.current;
    } else {
    }
  }, [state.feedContainerRef?.current?.scrollHeight, feedContainerRef, state.selectedCase]);

  const isMessageForThisCase = (message: DbRecordEntityTransform) => {
    return message?.caseId === caseIdRef?.current;
  };

  const handleClientTypingEvent = (message: any) => {
    dispatch({ type: MY_CASES_SET_CLIENT_TYPING_TEXT, payload: message.message });
  };

  const handleIncomingEvent = (message: any) => {
    // console.log('debug: [WS] [My Cases] ANY incoming event', message);
    if (isMessageForThisCase(message)) {
      switch (message.type) {
        case 'MESSAGE_CREATED':
          handleMessageCreatedEvent(message);
          break;
        case 'CONVERSATION_CLOSED':
          handleConversationClosedEvent(message);
          break;
        case 'EMAIL_RECEIVED':
          handleEmailReceivedEvent(message);
          break;
        default:
          // console.log('debug: [WS] [My Cases] Unhandled message received', message);
          break;
      }
    }
  };

  // Handle global case events, published for cases that you can have in the list on My Cases view.
  // We omit events for the selected case (currently viewed), as they are handled by handleIncomingEvent.
  const handleIncomingGlobalEvent = (message: any) => {
    dispatch({ type: MY_CASES_UPDATE_CASE, payload: message.data as DbRecordEntityTransform });
  };

  // MESSAGE_CREATED Event Handler
  const handleMessageCreatedEvent = (message: any) => {
    console.log('%cdebug: [WS] [My Cases] MESSAGE_CREATED', 'color: #bada55', message);
    dispatch({ type: MY_CASES_ADD_MESSAGE, payload: message.data as DbRecordEntityTransform });

    // Check if own message by comparing from property with userReducer.user.email
    if (message.data.createdBy?.id === userReducer.user?.id) {
      if (feedContainerRef) {
        feedContainerRef.current.scrollTo({
          top: feedContainerRef.current.scrollTop + feedContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    } else {
      dispatch({ type: MY_CASES_SET_CLIENT_TYPING_TEXT, payload: '' });
    }
  };

  // CONVERSATION_CLOSED Event Handler
  const handleConversationClosedEvent = (message: any) => {
    console.log('%cdebug: [WS] [My Cases] CONVERSATION_CLOSED', 'color: #bada55', message);
    dispatch({ type: MY_CASES_CLOSE_CONVERSATION, payload: true });
  };

  // EMAIL_RECEIVED Event Handler
  const handleEmailReceivedEvent = (message: any) => {
    console.log('%cdebug: [WS] [My Cases] EMAIL_RECEIVED', 'color: #bada55', message);
    dispatch({ type: MY_CASES_ADD_EMAIL, payload: message.data as DbRecordEntityTransform });
  };

  return <></>;
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesWebSockets);
