import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { MyCasesContext } from '../../..';
import DOMPurify from 'dompurify';
import './styles.scss';

interface Props {}

const ClientTypingMessage: React.FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(MyCasesContext);

  const messageText: string = state.clientTypingText;

  const sanitizedMessage = DOMPurify.sanitize(messageText || '');

  return (
    <Row key={messageText} justify="start" style={{ padding: '18px 22px 18px 18px' }}>
      <Col>
        <Row>
          {/* Message */}
          <Col span={24} className="myCasesTypingMessage">
            <span dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
            <span className="dot"> .</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
          </Col>

          {/* Client Name and Timestamp */}
          <Col span={24} style={{ color: '#727c93' }}>
            <Row justify="space-between">
              <Col span={24}>
                <span style={{ fontStyle: 'italic' }}>typing </span>
                <span className="dot">.</span>
                <span className="dot">.</span>
                <span className="dot">.</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ClientTypingMessage;
