import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from '@blueprintjs/core';

import { httpGet } from '../../../../../../../../shared/http/requests';
import { displayMessage } from '../../../../../../../../shared/system/messages/store/reducers';

interface Props {
  schemaId: string;
  alertMessage: (params: { body: string; type: string }) => void;
}

const SyncElasticIndexAPI: React.FC<Props> = (props: Props) => {
  const { schemaId, alertMessage } = props;
  const [isSyncing, setIsSyncing] = useState<boolean>(false);

  const sync = () => {
    setIsSyncing(true);
    httpGet(`SchemaModule/v1.0/schemas/sync-elastic-index/${schemaId}`)
      .then(() => {
        setIsSyncing(false);
        alertMessage({ body: 'Syncing complete', type: 'success' });
      })
      .catch((e: any) => {
        setIsSyncing(false);
        alertMessage({ body: 'Syncing failed. ' + e.message, type: 'error' });
      });
  };

  return <Button onClick={sync} text="Start Sync" loading={isSyncing} />;
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

const mapState = (state: any) => ({});

export default connect(mapState, mapDispatch)(SyncElasticIndexAPI);
