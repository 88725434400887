import { useEffect, useState } from "react";
import { Section, Spinner } from "@blueprintjs/core"
import { Col, Row } from "antd"

import { SchemaEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity";

import { Empty } from "./Empty";
import { DetailsSection } from "./DetailsSection";
import { PropertiesSection } from "./PropertiesSection";
import { ActionsSection } from "./ActionsSection";
import { LinksSection } from "./LinksSection";
import { SchemaTypesSelect } from "./DetailsSection/SchemaTypesSelect";
import { PermissionsSection } from "./PermissionsSection";

type SchemaDetailsProps = {
  schema?: SchemaEntity;
  loading: boolean;
};

export const SchemaDetails = ({ schema, loading }: SchemaDetailsProps) => {
  const [schemaTypeId, setSchemaTypeId] = useState<string | null>(null);

  useEffect(() => {
    if (!schema?.id) return;

    if (schema.types?.length) {
      setSchemaTypeId('all');
    }
  }, [schema?.id]);

  if (loading || !schema)
    return (
      <div className="loading-page">
        <Spinner />
      </div>
    );

  return (
    <div style={{ padding: '1rem 10rem', display: 'grid', gap: '1rem' }}>
      <Row>
        <Col span={24}>
          <DetailsSection
            schema={schema}
            rightElement={
              <SchemaTypesSelect
                schemaTypes={schema.types}
                selected={schemaTypeId}
                onChange={val => setSchemaTypeId(val)}
              />
            }
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <PropertiesSection
            schema={schema}
            schemaTypeId={schemaTypeId}
          />
        </Col>
        <Col span={12}>
          <ActionsSection
            schema={schema}
            schemaTypeId={schemaTypeId}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <LinksSection schema={schema} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PermissionsSection schema={schema} schemaTypeId={schemaTypeId} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={15}>
          <Section title="Data">
            <Empty icon="th" text="No data available" />
          </Section>
        </Col>
        <Col span={9}>
          <Section title="Usage">
            <Empty icon="chart" text="No usage data available" />
          </Section>
        </Col>
      </Row>
    </div>
  )
}
