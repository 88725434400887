import { useState } from "react";
import { Card, CardList, InputGroup, Section, SectionCard } from "@blueprintjs/core"

import { SchemaDetailsProps } from "../types"
import { Empty } from "../Empty";

export const PermissionsSection = ({ schema }: SchemaDetailsProps) => {
  const [search, setSearch] = useState('');

  if (!schema) return null;

  const filteredPermissions = schema.permissions?.filter(permission => {
    const nameFilter = permission.name.toLocaleLowerCase().includes(search);

    return nameFilter;
  }) || [];

  return (
    <>
      <Section
        className="permissions-section"
        title="Permissions"
        rightElement={
          <>
            <InputGroup
              type="text"
              leftIcon="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
          </>
        }
      >
        <SectionCard padded={false} className="limited-height">
          {filteredPermissions.length === 0 ? (
            <Empty icon="text-highlight" text="No permissions defined yet" />
          ) : (
            <CardList bordered={false}>
            {filteredPermissions.map((permission) => (
              <Card key={permission.id} interactive>
                <span style={{ fontWeight: 700 }}>{permission.name}</span>
                <span style={{ minWidth: "50%", marginLeft: "auto" }}>{permission.description}</span>
                <span className="actions">
                  {/* <Icon
                    icon="edit"
                    className={Classes.TEXT_MUTED}
                    onClick={() => showActionsForm(action)}
                  />
                  <Icon
                    icon="new-text-box"
                    className={Classes.TEXT_MUTED}
                    onClick={() => setSelectedAction(action)}
                  />
                  <Icon
                    icon="trash"
                    className={Classes.TEXT_MUTED}
                    onClick={() => setActionToRemove(action)}
                  /> */}
                </span>
              </Card>
            ))}
            </CardList>
          )}
        </SectionCard>
      </Section>
    </>
  )
}
