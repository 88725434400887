import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Image, Row } from 'antd';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { httpGet } from '../../../../../../../../shared/http/requests';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Tooltip } from '@blueprintjs/core';

interface Props {
  fileIds: string;
  thumbSize?: number; // <- pixels for the width and height of the thumbnail
  justify?: 'start' | 'end';
}

const MyCaseAttachmentList: React.FC<Props> = (props: Props) => {
  const { fileIds, thumbSize, justify } = props;
  const [fileList, setFileList] = useState<DbRecordEntityTransform[]>([]);

  useEffect(() => {
    if (fileIds.length > 0) {
      fetchFilesByIds();
    }
  }, [fileIds]);

  const fetchFilesByIds = async () => {
    if (fileIds.length > 0) {
      try {
        httpGet(`SchemaModule/v1.0/db/File/many?ids=${fileIds}`).then((response: any) => {
          const newFileList = response.data?.data?.map((file: DbRecordEntityTransform) => {
            return file;
          });
          setFileList(newFileList);
        });
      } catch (e: any) {}
    }
  };

  const getThumbByMimeType = (mimeType: string) => {
    switch (mimeType) {
      case 'application/pdf':
        return <i className="bi bi-filetype-pdf" style={{ color: 'white', fontSize: '2em' }} />;
      default:
        return (
          <i className="bi bi-file-earmark-text" style={{ color: 'white', fontSize: '2em' }} />
        );
    }
  };

  const getThumbColorByMimeType = (mimeType: string) => {
    switch (mimeType) {
      case 'application/pdf':
        return '#f32929';
      default:
        return '#2D71D2';
    }
  };

  return (
    <Row justify={justify || 'end'}>
      {fileList.map((file: any, i: number) => (
        <Col
          key={file.id}
          style={{
            marginRight: i === fileIds.length - 1 ? 0 : '10px',
          }}
        >
          {getProperty(file, 'Mimetype').indexOf('image') > -1 ? (
            <Image
              src={getProperty(file, 'Url')}
              width={`${thumbSize || 80}px`}
              height={`${thumbSize || 80}px`}
              style={{ borderRadius: 5 }}
            />
          ) : (
            <Tooltip content={file.title} hoverOpenDelay={1000}>
              <a href={getProperty(file, 'Url')} target="_blank" rel="noreferrer">
                <div
                  style={{
                    width: thumbSize || 80,
                    height: thumbSize || 80,
                    borderRadius: 5,
                    cursor: 'pointer',
                    background: getThumbColorByMimeType(getProperty(file, 'Mimetype')),
                    verticalAlign: 'middle',
                  }}
                >
                  <Row style={{ height: '100%' }} align="middle">
                    <Col span={24} style={{ textAlign: 'center' }}>
                      {getThumbByMimeType(getProperty(file, 'Mimetype'))}
                    </Col>
                  </Row>
                </div>
              </a>
            </Tooltip>
          )}
        </Col>
      ))}
    </Row>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCaseAttachmentList);
