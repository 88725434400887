import React, { useContext, useState } from 'react';
import { Alert, Button } from '@blueprintjs/core';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { MyCasesContext } from '../../../index';
import { displayMessage } from '../../../../../../../../shared/system/messages/store/reducers';
import { httpPost } from '../../../../../../../../shared/http/requests';
import { connect } from 'react-redux';

interface Props {
  alertMessage: (params: { body: string; type: string }) => void;
  userReducer: any;
}

const CloseConversationButton: React.FC<Props> = (props: Props) => {
  const { alertMessage, userReducer } = props;
  const { state, dispatch } = useContext(MyCasesContext);

  const [isCloseConversationAlertVisible, setIsCloseConversationAlertVisible] =
    useState<boolean>(false);
  const [isClosingConversation, setIsClosingConversation] = useState<boolean>(false);

  const isCaseOwn = getProperty(state.selectedCase, 'OwnerId') === userReducer.user.id;

  const isConversationClosed = (): boolean => {
    return (
      !state.selectedCaseConversation ||
      getProperty(state.selectedCaseConversation, 'Status') === 'CLOSED'
    );
  };

  const isActionDisabled = () => {
    return !state.selectedCase || isConversationClosed() || !isCaseOwn;
  };

  const closeConversation = async () => {
    if (state.selectedCaseConversation) {
      try {
        setIsClosingConversation(true);
        httpPost(
          `ChatModule/v1.0/gateway/conversation/${state.selectedCaseConversation.id}/close`,
          {},
        );
        setIsClosingConversation(false);
        setIsCloseConversationAlertVisible(false);
        alertMessage({ body: 'Conversation closed successfully.', type: 'success' });
      } catch (e: any) {
        alertMessage({ body: e.message, type: 'error' });
        setIsClosingConversation(false);
        setIsCloseConversationAlertVisible(false);
      }
    }
  };

  return (
    <>
      <Button
        disabled={isActionDisabled()}
        intent="danger"
        text={'Close Conversation'}
        outlined={true}
        style={{ borderRadius: 5 }}
        onClick={() => setIsCloseConversationAlertVisible(true)}
      />
      {/* Close Conversation Alert */}
      <Alert
        intent="danger"
        onCancel={() => setIsCloseConversationAlertVisible(false)}
        isOpen={isCloseConversationAlertVisible}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        onConfirm={closeConversation}
        loading={isClosingConversation}
      >
        <p>Please confirm that you want to close this conversation</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CloseConversationButton);
