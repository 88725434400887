import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import './styles.scss';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import DOMPurify from 'dompurify';
import MyCaseAttachmentList from '../MyCaseAttachmentList';

interface Props {
  message: any;
  caseSchema: SchemaEntity;
}

const MyCasesMessage: React.FC<Props> = (props: Props) => {
  const { message } = props;

  let messageBody = getProperty(message, 'Body');
  messageBody = messageBody?.replace(/(?:\r\n|\r|\n)/g, '<br>');

  const sanitizedMessage = DOMPurify.sanitize(messageBody);

  let fileIds: string = getProperty(message, 'Files') || '';
  const isOwnMessage: boolean = !['Website Api', 'Website API'].includes(
    message.createdBy?.fullName,
  );

  return (
    <Row
      key={message.hourSent}
      justify={`${isOwnMessage ? 'end' : 'start'}`}
      style={{ padding: '18px 22px 18px 18px' }}
    >
      {/* CLIENT Message */}
      {!isOwnMessage ? (
        <Col>
          <Row>
            {/* Message */}
            <Col span={24} className="myCasesClientMessage">
              <span dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />

              {fileIds.length > 0 && (
                <Col span={24} style={{ marginTop: 15, marginBottom: 5 }}>
                  <MyCaseAttachmentList
                    fileIds={fileIds}
                    thumbSize={60}
                    justify={isOwnMessage ? 'end' : 'start'}
                  />
                </Col>
              )}
            </Col>

            {/* Client Name and Timestamp */}
            <Col span={24} style={{ color: '#727c93' }}>
              <Row justify="space-between">
                <Col>
                  <span>{getProperty(message, 'From') || 'New Account'}</span>
                </Col>
                <Col>
                  <span>{dayjs(message.createdAt).format('h:mm:ss A')}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : (
        // USER Message
        <Col>
          <Row>
            {/* Message */}
            <Col span={24} className="myCasesUserMessage">
              <span
                style={{ overflowWrap: 'break-word' }}
                dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
              />
              {/* File Attachments */}
              {fileIds.length > 0 && (
                <Col span={24} style={{ marginTop: messageBody ? 15 : 0, marginBottom: 5 }}>
                  <MyCaseAttachmentList fileIds={fileIds} thumbSize={60} />
                </Col>
              )}
            </Col>
            {/* Timestamp */}
            <Col span={24}>
              <Col style={{ color: '#727c93' }}>
                <Row justify="space-between">
                  <Col>
                    <span>{message.createdBy?.fullName || 'Agent'}</span>
                  </Col>
                  <Col>
                    <span>{dayjs(message.createdAt).format('h:mm:ss A')}</span>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default MyCasesMessage;
