import { Alert, Button, Section } from '@blueprintjs/core';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { Col, Row, Table } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateSchemaType,
  createSchemaTypeRequest,
  DeleteSchemaType,
  deleteSchemaTypeRequest,
} from '../../../../../../../core/schemas/store/actions';
import { ISchemaReducer } from '../../../../../../../core/schemas/store/reducer';
import FormModal, {
  FormReducerSubmitEvt,
} from '../../../../../../../shared/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '../../../../../../../shared/components/SharedForm/store/actions';
import { SharedFormReducer } from '../../../../../../../shared/components/SharedForm/store/reducer';
import { renderBooleanValue } from '../../../../../../shared/helpers/UIHelpers';
import { formFields } from './formFields';

interface Props {
  schema: SchemaEntity | undefined;
  schemaReducer: ISchemaReducer;
  formReducer: SharedFormReducer;
  initializeForm: any;
  createType: (params: CreateSchemaType) => void;
  deleteType: (params: DeleteSchemaType) => void;
}

interface State {
  schemaTypeToDelete: string | undefined;
  deleteSchemaAlertVisible: boolean;
}

const uuid = uuidv4();

class SchemaTypesList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      schemaTypeToDelete: undefined,
      deleteSchemaAlertVisible: false,
    };
  }

  showCreateForm() {
    const { schema, initializeForm } = this.props;

    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Schema Type',
      formFields: formFields,
      entityName: 'SchemaType',
      pipelineId: schema?.id,
    });
  }

  handleFormSubmit(params: FormReducerSubmitEvt) {
    const { createType, schema, formReducer } = this.props;

    if (schema && params.data && !formReducer.isUpdateReq) {
      const body = {
        schemaId: schema?.id,
        name: params.data.name,
        label: params.data.label,
        description: params.data.description,
        isDefault: params.data.isDefault,
      };

      createType({ schemaId: schema.id, body });
    }
  }

  handleDelete = () => {
    const { schema, deleteType } = this.props;
    const { schemaTypeToDelete } = this.state;

    if (schema && schemaTypeToDelete) {
      deleteType({ schemaId: schema?.id, schemaTypeId: schemaTypeToDelete });
      this.closeModal();
    } else {
      this.closeModal();
    }
  };

  closeModal = () => {
    this.setState({ deleteSchemaAlertVisible: false, schemaTypeToDelete: undefined });
  };

  render() {
    const { schema } = this.props;

    const columns = [
      { title: 'Name', dataIndex: 'name' },
      { title: 'Label', dataIndex: 'label' },
      { title: 'Description', dataIndex: 'description' },
      {
        title: 'Is Default',
        dataIndex: 'isDefault',
        render: (text: any) => renderBooleanValue(String(text)),
      },
      {
        title: '',
        dataIndex: 'operation',
        align: 'right',
        render: (text: any, record: any) => (
          <Button
            icon="trash"
            small
            outlined
            intent="danger"
            onClick={() => {
              this.setState({ deleteSchemaAlertVisible: true, schemaTypeToDelete: record.key });
            }}
          />
        ),
      },
    ];

    const data = schema?.types?.map((type: SchemaTypeEntity) => ({
      key: type.id,
      name: type.name,
      description: type.description,
      label: type.label,
      isDefault: type.isDefault,
    }));

    return (
      <>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => this.handleFormSubmit(params)}
        />

        <Alert
          intent="danger"
          canEscapeKeyCancel
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          isOpen={this.state.deleteSchemaAlertVisible}
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onConfirm={this.handleDelete}
        >
          <p>Are you sure you want to delete this schema type? This action cannot be undone.</p>
        </Alert>

        <Section
          title="Schema Types"
          rightElement={
            <Button
              intent="success"
              icon="plus"
              onClick={() => this.showCreateForm()}
              text="Create Type"
            />
          }
        >
          <Row>
            <Col span={24}>
              <Table
                size="small"
                bordered={false}
                pagination={false}
                dataSource={data}
                columns={columns as any}
              />
            </Col>
          </Row>
        </Section>
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  createType: (params: CreateSchemaType) => dispatch(createSchemaTypeRequest(params)),
  deleteType: (params: DeleteSchemaType) => dispatch(deleteSchemaTypeRequest(params)),
});

export default connect(mapState, mapDispatch)(SchemaTypesList);
