import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  MY_CASES_ADD_EMAIL,
  MY_CASES_ADD_MESSAGE,
  MY_CASES_ADD_NOTE,
  MY_CASES_ADD_NOTE_TO_SELECTED_CASE,
  MY_CASES_CLOSE_CONVERSATION,
  MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES,
  MY_CASES_SET_ACTIVE_TAB,
  MY_CASES_SET_AGENT_INPUT_TEXT,
  MY_CASES_SET_ASSOCIATED_ACCOUNTS,
  MY_CASES_SET_ASSOCIATED_CASES_FOR_CONTACT,
  MY_CASES_SET_CASES,
  MY_CASES_SET_CLIENT_TYPING_TEXT,
  MY_CASES_SET_CONTACT,
  MY_CASES_SET_FEED_BOTTOM_REF,
  MY_CASES_SET_FEED_CONTAINER_REF,
  MY_CASES_SET_IS_SCROLL_DOWN_BUTTON_VISIBLE,
  MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT,
  MY_CASES_SET_SELECTED_ACCOUNT,
  MY_CASES_SET_SELECTED_CASE,
  MY_CASES_SET_SELECTED_CASE_CALLS,
  MY_CASES_SET_SELECTED_CASE_CONVERSATION,
  MY_CASES_SET_SELECTED_CASE_EMAILS,
  MY_CASES_SET_SELECTED_CASE_MESSAGES,
  MY_CASES_SET_SELECTED_CASE_NOTES,
  MY_CASES_SET_SINGLE_CASE_MODE,
  MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL,
  MY_CASES_TOGGLE_EMAIL_EDITOR,
  MY_CASES_TOGGLE_MESSAGE_EDITOR,
  MY_CASES_TOGGLE_NOTE_EDITOR,
  MY_CASES_UPDATE_CASE,
} from './constants';

export interface IMyCasesReducer {
  agentInputText: string;
  cases: DbRecordEntityTransform[];
  isAddingNote: boolean;
  isComposingEmail: boolean;
  isComposingMessage: boolean;
  isContactPanelOpen: boolean;
  isLoadingCase: boolean;
  isLoadingCases: boolean;
  isSearchingForAssociatedContact: boolean;
  isSingleCaseMode: boolean;
  isUpdatingCase: boolean;
  selectedCase: DbRecordEntityTransform | undefined;
  selectedCaseContact: DbRecordEntityTransform | undefined;
  selectedCaseContact_Accounts: DbRecordEntityTransform[];
  selectedCaseContact_SelectedAccount: DbRecordEntityTransform | undefined;
  selectedCaseContact_Cases: DbRecordEntityTransform[];
  isLoadingSelectedCaseContact_Cases: boolean;
  selectedCaseConversation: DbRecordEntityTransform | undefined;
  selectedCaseEmails: DbRecordEntityTransform[];
  selectedCaseMessages: DbRecordEntityTransform[];
  selectedCaseNotes: DbRecordEntityTransform[];
  selectedCaseCalls: DbRecordEntityTransform[];
  selectedCasesTabId: 'my-queue' | 'following';
  isScrollDownButtonVisible: boolean;
  feedContainerRef: React.RefObject<HTMLDivElement> | undefined;
  feedBottomRef: React.RefObject<HTMLDivElement> | undefined;
  clientTypingText: string;
}

export const MyCasesReducerInitialState: IMyCasesReducer = {
  agentInputText: '',
  cases: [],
  isAddingNote: false,
  isComposingEmail: false,
  isComposingMessage: false,
  isContactPanelOpen: false,
  isLoadingCase: false,
  isLoadingCases: false,
  isSearchingForAssociatedContact: false,
  isUpdatingCase: false,
  isSingleCaseMode: false,
  selectedCase: undefined,
  selectedCaseContact: undefined,
  selectedCaseContact_Accounts: [],
  selectedCaseContact_SelectedAccount: undefined,
  selectedCaseContact_Cases: [],
  isLoadingSelectedCaseContact_Cases: false,
  selectedCaseConversation: undefined,
  selectedCaseEmails: [],
  selectedCaseMessages: [],
  selectedCaseNotes: [],
  selectedCaseCalls: [],
  selectedCasesTabId: 'my-queue',
  feedBottomRef: undefined,
  feedContainerRef: undefined,
  isScrollDownButtonVisible: false,
  clientTypingText: '',
};

export function myCasesReducer(state: IMyCasesReducer, action: { type: string; payload: any }) {
  switch (action.type) {
    case MY_CASES_SET_CASES:
      return { ...state, cases: action.payload };
    case MY_CASES_SET_SELECTED_CASE:
      return {
        ...state,
        clientTypingText: '',
        selectedCase: action.payload,
      };
    case MY_CASES_SET_SELECTED_CASE_MESSAGES:
      return { ...state, selectedCaseMessages: action.payload };
    case MY_CASES_SET_SELECTED_CASE_CONVERSATION:
      return { ...state, selectedCaseConversation: action.payload };
    case MY_CASES_SET_SELECTED_CASE_EMAILS:
      return { ...state, selectedCaseEmails: action.payload };
    case MY_CASES_SET_SELECTED_CASE_NOTES:
      return { ...state, selectedCaseNotes: action.payload };
    case MY_CASES_SET_SELECTED_CASE_CALLS:
      return { ...state, selectedCaseCalls: action.payload };
    case MY_CASES_TOGGLE_NOTE_EDITOR:
      return { ...state, isAddingNote: !state.isAddingNote };
    case MY_CASES_TOGGLE_CONTACT_DETAILS_PANEL:
      return { ...state, isContactPanelOpen: !state.isContactPanelOpen };
    case MY_CASES_SET_ACTIVE_TAB:
      return { ...state, selectedCasesTabId: action.payload };
    case MY_CASES_ADD_NOTE_TO_SELECTED_CASE:
      return { ...state, selectedCaseNotes: [...state.selectedCaseNotes, action.payload] };
    case MY_CASES_SET_CONTACT:
      return { ...state, selectedCaseContact: action.payload };
    case MY_CASES_TOGGLE_EMAIL_EDITOR:
      return {
        ...state,
        isComposingEmail: !state.isComposingEmail,
      };
    case MY_CASES_TOGGLE_MESSAGE_EDITOR:
      console.log('debug: REDUCER!', !state.isComposingMessage);
      return {
        ...state,
        isComposingMessage: !state.isComposingMessage,
      };
    case MY_CASES_ADD_MESSAGE:
      return { ...state, selectedCaseMessages: [...state.selectedCaseMessages, action.payload] };
    case MY_CASES_SET_FEED_BOTTOM_REF:
      return { ...state, feedBottomRef: action.payload };
    case MY_CASES_SET_FEED_CONTAINER_REF:
      return { ...state, feedContainerRef: action.payload };
    case MY_CASES_SET_IS_SCROLL_DOWN_BUTTON_VISIBLE:
      return { ...state, isScrollDownButtonVisible: action.payload };
    case MY_CASES_SET_IS_SEARCHING_FOR_ASSOCIATED_CONTACT:
      return { ...state, isSearchingForAssociatedContact: action.payload };
    case MY_CASES_SET_ASSOCIATED_ACCOUNTS:
      return { ...state, selectedCaseContact_Accounts: action.payload };
    case MY_CASES_SET_ASSOCIATED_CASES_FOR_CONTACT:
      return { ...state, selectedCaseContact_Cases: action.payload };
    case MY_CASES_IS_LOADING_SELECTED_CASE_CONTACT_CASES:
      return { ...state, isLoadingSelectedCaseContact_Cases: action.payload };
    case MY_CASES_ADD_EMAIL:
      return { ...state, selectedCaseEmails: [...state.selectedCaseEmails, action.payload] };
    case MY_CASES_ADD_NOTE:
      return { ...state, selectedCaseNotes: [...state.selectedCaseNotes, action.payload] };
    case MY_CASES_UPDATE_CASE:
      const updatedCase = action.payload;
      const updatedCases = state.cases.map((c) => (c.id === updatedCase.id ? updatedCase : c));

      // If no cases in the updated cases list match the updatedCase.id, then we need to add the updatedCase to the list.
      // The reason for this is that when a new case is assigned to user, websocket event will publish CASE_UPDATED event
      // if (updatedCases.every((c) => c.id !== updatedCase.id)) {
      //   updatedCases.push(updatedCase);
      // }

      return {
        ...state,
        cases: updatedCases,
        selectedCaseContact_Cases: state.selectedCaseContact_Cases.map(
          (c: DbRecordEntityTransform) => (c.id === updatedCase.id ? updatedCase : c),
        ),
        selectedCase: state.selectedCase?.id === updatedCase.id ? updatedCase : state.selectedCase,
      };
    case MY_CASES_SET_SELECTED_ACCOUNT:
      return { ...state, selectedCaseContact_SelectedAccount: action.payload };
    case MY_CASES_SET_SINGLE_CASE_MODE:
      return { ...state, isSingleCaseMode: action.payload };
    case MY_CASES_CLOSE_CONVERSATION:
      return {
        ...state,
        selectedCaseConversation: {
          ...state.selectedCaseConversation,
          properties: {
            ...state.selectedCaseConversation?.properties,
            Status: 'CLOSED',
          },
        },
      };
    case MY_CASES_SET_AGENT_INPUT_TEXT:
      return { ...state, agentInputText: action.payload };
    case MY_CASES_SET_CLIENT_TYPING_TEXT:
      return { ...state, clientTypingText: action.payload };
    default:
      throw new Error();
  }
}
