import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';
import { getCaseUserFromRecord } from '../../../helpers';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SupportDashboardContext } from '../../../index';
import { initializeRecordForm } from '../../../../../../../../core/records/components/Forms/store/actions';
import CoreForm from '../../../../../../../../core/records/components/Forms/CoreForm';
import {
  IUpdateRecordById,
  updateRecordByIdRequest,
} from '../../../../../../../../core/records/store/actions';
import { displayMessage } from '../../../../../../../../shared/system/messages/store/reducers';
import { getOdinSchemaByEntity } from '../../../../../../../../shared/utilities/schemaHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';

interface Props {
  caseRecord: DbRecordEntityTransform;
  initializeForm: (params: any) => void;
  onRecordUpdate: (record: DbRecordEntityTransform) => void;
  userReducer: any;
  updateRecord: (params: IUpdateRecordById, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  assignOwnerAction?: SchemaActionEntity;
}

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const CASE = 'Case';

const AssignAgentToCaseDropdown: React.FC<Props> = (props: Props) => {
  const { caseRecord, initializeForm, onRecordUpdate, userReducer, updateRecord } = props;
  const [caseSchema, setCaseSchema] = useState<SchemaEntity | undefined>(undefined);

  const { assignOwnerAction } = useContext(SupportDashboardContext);

  useEffect(() => {
    if (!caseSchema) {
      getCaseSchema();
    }
  }, [caseSchema]);

  const getCaseSchema = async () => {
    try {
      const schema = await getOdinSchemaByEntity(SUPPORT_MODULE, CASE);
      setCaseSchema(schema);
    } catch (e: any) {}
  };

  const [isAssignToMyselfDialogVisible, setIsAssignToMyselfDialogVisible] =
    useState<boolean>(false);
  const [isAssigningToMyself, setIsAssigningToMyself] = useState<boolean>(false);

  const [isRemoveAssignmentDialogVisible, setIsRemoveAssignmentDialogVisible] =
    useState<boolean>(false);
  const [isRemovingAssignment, setisRemovingAssignment] = useState<boolean>(false);

  const userId = userReducer?.user?.id;
  const teamId = userReducer?.user?.teamId;

  const initializeAssignCaseForm = () => {
    if ((props.assignOwnerAction || assignOwnerAction) && caseSchema) {
      initializeForm({
        hideRecordTypeField: true,
        formUUID: `assignCaseForm_${caseRecord.id}`,
        showFormModal: true,
        title: 'Assign Case to Agent',
        showInitializing: false,
        isBatchCreateReq: false,
        schema: caseSchema,
        hideRecordFormFields: true,
        recordType: caseRecord.type || null,
        schemaActionId: props.assignOwnerAction?.id || assignOwnerAction?.id,
        isCreateReq: false,
        isUpdateReq: true,
        selected: caseRecord,
      });
    }
  };

  const handleFormSubmit = (params: { event: string; results: any }) => {
    const record = params.results;
    onRecordUpdate(record);
  };

  const assignToMyselfRequest = () => {
    if (caseSchema && caseRecord) {
      try {
        setIsAssigningToMyself(true);
        updateRecord(
          {
            schema: caseSchema,
            recordId: caseRecord.id!,
            createUpdate: {
              id: caseRecord?.id,
              entity: caseRecord?.entity,
              type: caseRecord?.type,
              properties: {
                OwnerId: userId,
                TeamId: teamId,
              },
            },
          },
          (res: any) => {
            setIsAssignToMyselfDialogVisible(false);
            setIsAssigningToMyself(false);
            onRecordUpdate(res);
          },
        );
      } catch (e: any) {
        setIsAssignToMyselfDialogVisible(false);
        setIsAssigningToMyself(false);
      }
    } else {
      console.log('caseSchema', caseSchema);
      console.log('caseRecord', caseRecord);
    }
  };

  const removeAssignmentFromCase = () => {
    if (caseSchema && caseRecord) {
      try {
        setisRemovingAssignment(true);
        updateRecord(
          {
            schema: caseSchema,
            recordId: caseRecord.id!,
            createUpdate: {
              id: caseRecord?.id,
              entity: caseRecord?.entity,
              type: caseRecord?.type,
              properties: {
                OwnerId: null,
                TeamId: null,
              },
            },
          },
          (res: any) => {
            setIsRemoveAssignmentDialogVisible(false);
            setisRemovingAssignment(false);
            onRecordUpdate(res);
          },
        );
      } catch (e: any) {
        setIsRemoveAssignmentDialogVisible(false);
        setisRemovingAssignment(false);
      }
    }
  };

  return (
    <>
      {/* Alert when assigning Case to myself */}
      <Alert
        key={`assignCaseAlert1${caseRecord.id}`}
        intent="primary"
        onCancel={() => setIsAssignToMyselfDialogVisible(false)}
        isOpen={isAssignToMyselfDialogVisible}
        cancelButtonText="Cancel"
        confirmButtonText="Assign"
        canEscapeKeyCancel={!isAssigningToMyself}
        canOutsideClickCancel={!isAssigningToMyself}
        onConfirm={assignToMyselfRequest}
        loading={isAssigningToMyself}
      >
        <p key={`assignCaseP1${caseRecord.id}`}>
          Are you sure you want to assign this case to yourself?
        </p>
      </Alert>

      {/* Alert when removing assignment from Case */}
      <Alert
        key={`assignCaseAlert2${caseRecord.id}`}
        intent="danger"
        onCancel={() => setIsRemoveAssignmentDialogVisible(false)}
        isOpen={isRemoveAssignmentDialogVisible}
        cancelButtonText="Cancel"
        confirmButtonText="Remove"
        canEscapeKeyCancel={!isRemovingAssignment}
        canOutsideClickCancel={!isRemovingAssignment}
        onConfirm={removeAssignmentFromCase}
        loading={isRemovingAssignment}
      >
        <p key={`assignCaseP2${caseRecord.id}`}>
          Are you sure you want to remove the assignment from this case?
        </p>
      </Alert>

      <Popover
        key={`assignCasePopover${caseRecord.id}`}
        fill={true}
        content={
          <Menu key={`assignCaseMenu${caseRecord.id}`}>
            <MenuItem
              disabled={!props.assignOwnerAction && !assignOwnerAction}
              icon="team"
              text="Assign to agent"
              key={`assignCaseMenuItem1${caseRecord.id}`}
              onClick={initializeAssignCaseForm}
            />
            <MenuItem
              key={`assignCaseMenuItem2${caseRecord.id}`}
              icon="person"
              text="Assign to myself"
              disabled={getProperty(caseRecord, 'OwnerId') === userId}
              onClick={() => setIsAssignToMyselfDialogVisible(true)}
            />
            <MenuDivider key={`assignCaseMenuDivider${caseRecord.id}`} />
            <MenuItem
              intent="danger"
              icon="trash"
              text="Remove assignment"
              disabled={!getProperty(caseRecord, 'OwnerId')}
              key={`assignCaseMenuItem3${caseRecord.id}`}
              onClick={() => setIsRemoveAssignmentDialogVisible(true)}
            />
          </Menu>
        }
        placement="bottom"
      >
        <Button
          icon={getProperty(caseRecord, 'OwnerId') ? 'user' : null}
          alignText="left"
          rightIcon="caret-down"
          fill
          style={{
            borderRadius: 5,
            fontWeight: getProperty(caseRecord, 'OwnerId') ? 600 : 400,
          }}
          outlined={!getProperty(caseRecord, 'OwnerId')}
          key={`assignCaseButton${caseRecord.id}`}
          text={
            getProperty(caseRecord, 'OwnerId') ? getCaseUserFromRecord(caseRecord) : 'Unassigned'
          }
        />
      </Popover>
      <CoreForm
        type="MODAL"
        formUUID={`assignCaseForm_${caseRecord.id}`}
        onCloseEvent={() => {}}
        onSubmitEvent={(params: { event: string; results: any }) => handleFormSubmit(params)}
      />
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(AssignAgentToCaseDropdown);
