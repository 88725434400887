import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { Button, ButtonGroup, InputGroup, Popover, Tag } from '@blueprintjs/core';

import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { OdinDropdown, OdinDropdownOption } from '../../../modules/ControlPanelModule/OdinDropdown';
import { FilterDialog } from './FilterDialog';
import {
  useOdinSearchActions,
  useOdinSearchState,
} from '../../../modules/SupportModule/views/SupportDashboard/views/CaseManagementCaseListView/context/provider';
import { OdinSearchParams } from '../../../modules/SupportModule/views/SupportDashboard/views/CaseManagementCaseListView/context/types';

type SearchV2Filter = {
  columnName: string;
  operator: string;
  value: string | string[];
};

const paramsToOption = (filter: OdinSearchParams, index: number): OdinDropdownOption => ({
  id: `${filter.name || 'Unnamed'}_${index}`,
  text: filter.name || 'Unnamed',
});

export type OdinElasticFilter = {
  name?: string;
  values: SearchV2Filter[];
};

export type OdinElasticFilterProps = {
  schema?: SchemaEntity;
};

export const OdinElasticFilters: React.FC<OdinElasticFilterProps> = ({ schema }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [filterName, setFilterName] = React.useState<string>('');

  // Context state for filters
  const { selectedParams, savedParams } = useOdinSearchState();
  const { addFilter, removeFilter, saveParams, deleteParams, selectParams } =
    useOdinSearchActions();
  const selectedParamsIndex = savedParams.findIndex((p) => p.name === selectedParams?.name);

  // Local state for filters
  const [filterValues, setFilterValues] = useState<SearchV2Filter[]>([]);

  // Update filterValues based on selectedParams
  useEffect(() => {
    setFilterValues(selectedParams?.filters || []);
    if (selectedParams?.name) setFilterName(selectedParams.name);

    // Auto-save param on change
    if (selectedParams) saveParams(selectedParams);
  }, [selectedParams]);

  // Event handlers
  const handleOnConfirm = (filter: SearchV2Filter) => {
    addFilter(filter);
    setIsOpen(false);
  };

  const handleSaveFilterClick = () => {
    if (!schema?.id || !selectedParams) return;

    const newParams = { ...selectedParams, filters: filterValues, name: filterName };
    saveParams(newParams);
  };

  const handleDeleteFilterClick = () => {
    if (!schema?.id || !selectedParams) return;

    deleteParams(selectedParams);
    selectParams();
  };

  const filterOptions = savedParams.map(paramsToOption);

  // const shouldSaveFilter = () => {
  //   const paramsToEdit = selectedParams && savedParams.find((f) => f.name === selectedParams.name || JSON.stringify(f.filters) === JSON.stringify(selectedParams.filters));

  //   if (!paramsToEdit && filterValues.length > 0) return true;

  //   if (paramsToEdit && (
  //       JSON.stringify(paramsToEdit.filters) !== JSON.stringify(filterValues) ||
  //       JSON.stringify(paramsToEdit.sort) !== JSON.stringify(selectedParams?.sort)
  //     )
  //   ) return true;

  //   return false;
  // };

  const newParamsHandler = useCallback(() => {
    if (!schema?.id) return;

    const newParams = { name: 'New Filter', filters: [], selected: true };
    saveParams(newParams);
    setFilterName(newParams.name);
  }, [schema?.id, saveParams]);

  const changeFilterHandler = useCallback(
    (selected: OdinDropdownOption | null) => {
      if (!selected) return;

      const selectedFilter = savedParams.find((f) => f.name === selected.text);

      if (selectedFilter) {
        selectParams(selectedFilter);
        saveParams({ ...selectedFilter, selected: true });
      }
    },
    [savedParams, selectParams, saveParams],
  );

  if (!schema) return null;

  // console.log("[DebugX] savedParams", savedParams, selectedParams, selectedParamsIndex);
  const disableExtraActions = savedParams.length === 0 && !selectedParams;
  // console.log("[DebugX] disableExtraActions", disableExtraActions);
  return (
    <>
      <Row style={{ marginBottom: 5 }}>
        <Col span={20} style={{ display: 'flex' }}>
          <ButtonGroup>
            {savedParams.length > 0 && (
              <OdinDropdown
                placeholder={'Select a filter'}
                items={filterOptions}
                selectedItem={
                  selectedParams ? paramsToOption(selectedParams, selectedParamsIndex) : null
                }
                onChange={changeFilterHandler}
              />
            )}
            {savedParams.length > 0 && (
              <Button icon="trash" intent="danger" onClick={handleDeleteFilterClick} />
            )}
          </ButtonGroup>
          {filterValues.length > 0 && (
            <>
              {filterValues.map((filter) => (
                <Tag
                  intent="success"
                  minimal
                  key={`${filter.columnName}-${filter.operator}-${filter.value}`}
                  style={{
                    marginLeft: savedParams.length > 0 ? 5 : 0,
                    marginRight: 5,
                    borderRadius: 5,
                  }}
                  onRemove={() => {
                    removeFilter(filter);
                  }}
                >
                  {filter.columnName}: {filter.value}
                </Tag>
              ))}
              <Popover
                content={
                  <InputGroup
                    placeholder="Filter Name"
                    value={filterName}
                    onChange={(e) => setFilterName(e.target.value)}
                    rightElement={
                      <Button
                        icon="floppy-disk"
                        text="Save"
                        disabled={!filterName}
                        onClick={handleSaveFilterClick}
                      />
                    }
                  />
                }
              >
                <Button icon="floppy-disk" style={{ borderRadius: 5 }} />
              </Popover>
            </>
          )}
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <ButtonGroup>
            <Button
              style={{ borderTopLeftRadius: 5, borderBottomLeftRadius: 5 }}
              icon="plus"
              onClick={() => setIsOpen(true)}
            >
              Add Filter
            </Button>
            <Popover
              position="bottom"
              content={
                <ButtonGroup vertical style={{ minWidth: 120 }}>
                  <Button icon="filter" onClick={newParamsHandler}>
                    New Filter
                  </Button>
                </ButtonGroup>
              }
            >
              <Button
                style={{ borderTopRightRadius: 5, borderBottomRightRadius: 5 }}
                icon="caret-down"
                disabled={disableExtraActions}
              />
            </Popover>
          </ButtonGroup>
        </Col>
      </Row>
      <FilterDialog
        schema={schema}
        isOpen={isOpen}
        onConfirm={handleOnConfirm}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};
