import { Switch } from 'react-router-dom';

import RoleBasedProtectedRoute from '../../../core/navigation/RoleBasedProtectedRoute';
import IdentityManagerView from './containers/IdentityManager/IdentityManagerView';
import ControlPanelView from './containers/ControlPanelView';
import SchemaManagerView from './containers/SchemaManager/SchemaManagerView';
import SchemaDetailsView from './containers/SchemaManager/SchemaDetailsView';

const IDENTITY_MANAGER_MODULE = 'IdentityManagerModule';
const SCHEMA_MANAGER_MODULE = 'SchemaModule';

export const ControlPanelModuleRoutes = () => {
  return (
    <Switch>
      <RoleBasedProtectedRoute
        exact
        path={`/ControlPanelModule`}
        moduleName={IDENTITY_MANAGER_MODULE}
        component={<ControlPanelView />}
      />
      <RoleBasedProtectedRoute
        exact
        path={`/ControlPanelModule/IdentityManager`}
        moduleName={IDENTITY_MANAGER_MODULE}
        component={<IdentityManagerView />}
      />
      <RoleBasedProtectedRoute
        exact
        path={`/ControlPanelModule/SchemaManager`}
        moduleName={SCHEMA_MANAGER_MODULE}
        component={<SchemaManagerView />}
      />
      <RoleBasedProtectedRoute
        exact
        path={`/ControlPanelModule/SchemaManager/:id`}
        moduleName={SCHEMA_MANAGER_MODULE}
        component={<SchemaDetailsView />}
      />
    </Switch>
  );
};
