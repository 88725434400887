import React from 'react';
import { Checkbox, Col, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { SchemaDetailsProps } from '../types';
import { Button, Switch } from '@blueprintjs/core';

import { updateSchemaRequest } from '../../../../../../../../core/schemas/store/actions';

import { useRequest } from '../../../../../../../../containers/ProjectModule/hooks/useRequest';

export const RecordNumbersForm = ({ schema }: SchemaDetailsProps) => {
  const formRef = React.createRef<FormInstance>();
  const updateSchema = useRequest(updateSchemaRequest);

  const handleFormSubmit = () => {
    const data = formRef.current?.getFieldsValue();
    updateSchema({ schemaId: schema.id, data: { ...schema, ...data } });
  };

  return (
    <Form layout={'vertical'} ref={formRef} initialValues={schema}>
      <Col span={24} style={{ marginBottom: 50 }}>
        <h3 title="5" style={{ marginTop: 0, marginBottom: '1rem' }}>Record Numbers</h3>
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item
              className="form-item"
              label="Starting Record Number"
              name="recordNumber"
            >
              <Input
                placeholder="Starting Record Number"
                type="number"
              />
            </Form.Item>

            <Form.Item
              className="form-item"
              label="Record Number Prefix"
              name="recordNumberPrefix"
            >
              <Input
                placeholder="Record Number Prefix"
                onChange={(e) => formRef.current?.setFieldValue('recordNumberPrefix', e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item className="form-item" name="isSequential">
              <Switch
                large
                defaultChecked={schema.isSequential}
                onChange={(e) => formRef.current?.setFieldValue('isSequential', e.target.checked)}
              >
                Auto-increment Record Numbers
              </Switch>
            </Form.Item>
          </Col>
          <Col span={24} style={{ alignItems: 'self-end' }}>
            <Button intent="primary" onClick={handleFormSubmit}>Update</Button>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};
