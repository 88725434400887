import { Button, Callout } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { MyCasesContext } from '../../..';
import './styles.scss';

interface Props {
  call: DbRecordEntityTransform;
}

const MyCasesCall: React.FC<Props> = (props: Props) => {
  const { call } = props;
  const { state, dispatch } = useContext(MyCasesContext);

  const { selectedCase } = state;

  return (
    <Row justify="end" style={{ padding: 15 }}>
      <Col span={24} className="myCasesCall">
        <Row>
          {/* Note Title & Toolbar */}
          <Col span={12}>
            <i className="bi bi-soundwave" style={{ marginRight: 5 }} />
            <span style={{ fontWeight: 500 }}>Call</span>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button
              small
              minimal
              icon={<i className="bi bi-link-45deg" style={{ fontSize: '1.4em' }} />}
            />
          </Col>

          {/* Audio Player*/}
          <Col span={24} style={{ paddingTop: 10 }}>
            {getProperty(call, 'RecordingUrl') ? (
              <audio controls style={{ width: '100%' }}>
                <source src={getProperty(call, 'RecordingUrl')} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            ) : (
              <>
                <Callout icon="warning-sign" intent="danger" style={{ borderRadius: 7 }}>
                  Call recording is unavailable.
                </Callout>
              </>
            )}
          </Col>

          {/* User / Timestamp */}
          <Col span={24} style={{ marginTop: 12, opacity: 0.6 }}>
            <Row>
              <Col span={12}>
                <span>{call.createdBy?.fullName}</span>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <span>{dayjs(call.createdAt).format('D MMM HH:mm')}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default MyCasesCall;
