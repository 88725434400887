import { Layout } from 'antd';
import { FC, useContext } from 'react';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import DetailView from '../../../../shared/views/DetailView';
import FileDetailViewPreview from './FileDetailViewPreview';

const FileDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <Layout>
      <DetailView
        showCollaborators={true}
        middleColumnBottom={[<FileDetailViewPreview file={record!} />]}
      />
    </Layout>
  );
};

export default FileDetailView;
