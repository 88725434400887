import { Button, Callout, Section, SectionCard } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Table } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import OrderNetworkItems from '../../../../../containers/OrderModule/containers/Order/OrderNetworkItems';
import BookingModal from '../../../../../core/appointments/components/BookingModal';
import { sendConfirmationEmail } from '../../../../../core/notifications/email/store/actions';
import RecordCard from '../../../../../core/records/components/RecordCard';
import AssociationDataTable from '../../../../../core/recordsAssociations/components/AssociationDataTable';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../core/userInterface/store/actions';
import { httpGet } from '../../../../../shared/http/requests';
import { parseDateLocalizedHours } from '../../../../../shared/utilities/dateHelpers';
import { getBrowserPath } from '../../../../../shared/utilities/recordHelpers';
import { DetailViewContext } from '../../../../shared/components/DetailViewContextProvider';
import NoteFeed from '../../../../shared/components/NoteFeed';
import DetailView from '../../../../shared/views/DetailView';
import DetailsConfigurator from '../../../../shared/views/DetailView/DetailsConfigurator';
import CasesPendingCallout from '../../../SupportModule/components/CasesPendingCallout';
import AssociatedWorkOrdersCard from '../../components/AssociatedWorkOrdersCard';
import CreateLinkedWorkOrder from '../../components/CreateLinkedWorkOrder';
import WorkOrderEmailActionButton from '../../components/WorkOrderEmailActionButton';
import WorkOrderPendingCallout from '../../components/WorkOrderPendingCallout';
import './styles.scss';
import { WorkOrderHistory } from './WorkOrderHistory';

const { SERVICE_APPOINTMENT, ORDER, CONTACT, ADDRESS, WORK_ORDER } = SchemaModuleEntityTypeEnums;
const { FIELD_SERVICE_MODULE, ORDER_MODULE, CRM_MODULE } = SchemaModuleTypeEnums;
const ONT_REPLACEMENT_APPROVAL = 'OntReplacementApproval';

interface Props {
  sendConfirmation: any;
  isQuickView?: boolean;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const WorkOrderDetailView: FC<Props> = (props: Props) => {
  const { viewConfigurations, record } = useContext(DetailViewContext);
  const { isQuickView, openDrawer } = props;

  const [order, setOrderData] = useState<DbRecordEntityTransform | undefined>(undefined);
  const [changeReasons, setChangeReasonData] = useState<DbRecordEntityTransform[] | undefined>(
    undefined,
  );
  const [remediationRequiredReasons, setRemediationRequiredReasonData] = useState<
    DbRecordEntityTransform[]
  >([]);
  const [permissionToFailList, setPermissionToFailList] = useState<DbRecordEntityTransform[]>([]);
  const [ONTApprovalList, setONTApprovalList] = useState<DbRecordEntityTransform[]>([]);

  useEffect(() => {
    loadData();
  }, [record]);
  const loadData = () => {
    if (record) {
      httpGet(
        `${FIELD_SERVICE_MODULE}/v1.0/db-associations/${WORK_ORDER}/${record.id}/one-relation?entity=Order&withLinks=false`,
      )
        .then((res) => {
          if (res?.data['Order']?.dbRecords?.length! > 0) {
            setOrderData(res?.data['Order']?.dbRecords[0]);
          }
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      httpGet(
        `${FIELD_SERVICE_MODULE}/v1.0/db-associations/${WORK_ORDER}/${record.id}/one-relation?entity=ChangeReason&withLinks=false`,
      )
        .then((res) => {
          setChangeReasonData(res.data['ChangeReason']?.dbRecords);
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      httpGet(
        `${FIELD_SERVICE_MODULE}/v1.0/db-associations/${WORK_ORDER}/${record.id}/one-relation?entity=RemediationRequiredReason&withLinks=false`,
      )
        .then((res) => {
          setRemediationRequiredReasonData(res.data['RemediationRequiredReason']?.dbRecords);
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      httpGet(
        `${FIELD_SERVICE_MODULE}/v1.0/db-associations/${WORK_ORDER}/${record.id}/one-relation?entity=PermissionToFail&withLinks=false`,
      )
        .then((res) => {
          setPermissionToFailList(
            res.data['PermissionToFail']?.dbRecords?.filter(
              (elem: DbRecordEntityTransform) => getProperty(elem, 'ApprovalStatus') !== 'APPROVED',
            ),
          );
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });

      // Get ONT Replacement Approvals
      httpGet(
        `${FIELD_SERVICE_MODULE}/v1.0/db-associations/${WORK_ORDER}/${record.id}/one-relation?entity=${ONT_REPLACEMENT_APPROVAL}&withLinks=false`,
      )
        .then((res) => {
          setONTApprovalList(res.data[ONT_REPLACEMENT_APPROVAL]?.dbRecords || []);
        })
        .catch((err) => {
          console.error('Error loading ONT Replacement Approvals:', err);
        });
    }
  };

  const showChangeReasons = () => {
    if (changeReasons) {
      return (
        <div style={{ marginBottom: 15 }}>
          <Section title="Change Reasons" className="appointmentChangeReasonSection" compact>
            <Table
              bordered
              size="small"
              pagination={false}
              dataSource={changeReasons}
              columns={[
                {
                  title: 'Owner',
                  dataIndex: 'Owner',
                  render: (text: any, record: DbRecordEntityTransform) =>
                    getProperty(record, 'Owner'),
                },
                {
                  title: 'Reason',
                  dataIndex: 'ChangeReason',
                  render: (text: any, record: DbRecordEntityTransform) =>
                    getProperty(record, 'RescheduleReason') ||
                    getProperty(record, 'CancelReason') ||
                    getProperty(record, 'DelayReason') ||
                    getProperty(record, 'InformReason'),
                },
                {
                  title: 'Prev. Date',
                  dataIndex: 'PreviousDate',
                  render: (text: any, record: DbRecordEntityTransform) =>
                    getProperty(record, 'PreviousDate')
                      ? `${getProperty(record, 'PreviousDate')} ${getProperty(
                          record,
                          'PreviousTimeBlock',
                        )}`
                      : '',
                },
                {
                  title: 'Next Date',
                  dataIndex: 'NextDate',
                  render: (text: any, record: DbRecordEntityTransform) =>
                    getProperty(record, 'NextDate')
                      ? `${getProperty(record, 'NextDate')} ${getProperty(record, 'NextTimeBlock')}`
                      : '',
                },
                {
                  title: 'Created',
                  dataIndex: 'createdAt',
                  render: (text: any, record: DbRecordEntityTransform) =>
                    parseDateLocalizedHours(record?.createdAt),
                },
                {
                  title: 'Actions',
                  width: '10%',
                  align: 'right',
                  render: (text, record: DbRecordEntityTransform) => (
                    <Button
                      small
                      icon="eye-open"
                      onClick={() => {
                        openDrawer({
                          recordId: record.id,
                          moduleName: FIELD_SERVICE_MODULE,
                          entityName: 'ChangeReason',
                        });
                      }}
                    />
                  ),
                },
              ]}
            />
          </Section>
        </div>
      );
    }
  };

  const showONTApprovalList = () => {
    if (ONTApprovalList.length > 0) {
      return (
        <div style={{ marginBottom: 15 }}>
          <Section
            title="ONT Replacement Approvals"
            className="appointmentReplacementApprovalSection"
            compact
          >
            <Table
              bordered
              size="small"
              pagination={false}
              dataSource={ONTApprovalList}
              columns={[
                {
                  title: 'Record #',
                  dataIndex: 'recordNumber',
                  render: (text: any, record: DbRecordEntityTransform) => (
                    <span>{record?.recordNumber}</span>
                  ),
                  width: '20%',
                },
                {
                  title: 'Reason',
                  dataIndex: 'reason',
                  render: (text: any, record: DbRecordEntityTransform) =>
                    getProperty(record, 'Reason'),
                },
                {
                  title: 'Approval Status',
                  dataIndex: 'approvalStatus',
                  render: (text: any, record: DbRecordEntityTransform) =>
                    getProperty(record, 'ApprovalStatus'),
                },
                {
                  title: 'Actions',
                  width: '10%',
                  align: 'right',
                  render: (text, record: DbRecordEntityTransform) => (
                    <Button
                      icon="eye-open"
                      small
                      onClick={() => {
                        openDrawer({
                          recordId: record.id,
                          moduleName: FIELD_SERVICE_MODULE,
                          entityName: ONT_REPLACEMENT_APPROVAL,
                        });
                      }}
                    />
                  ),
                },
              ]}
            />
          </Section>
        </div>
      );
    }
  };

  // There are necessary overrides for the record type when
  // doing the schedule filtering for the booking modal.
  const recordTypeForBookingModal = () => {
    if (record?.type === 'INSTALL_BUSINESS') {
      return 'INSTALL';
    } else {
      return record?.type;
    }
  };

  return (
    <DetailView
      isQuickView={isQuickView}
      showCollaborators
      defaultTabKey={'Summary'}
      showCommunicationHistory={true}
      middleColumnTop={[
        getProperty(record, 'Status') === 'ON_HOLD' ? (
          <Callout intent={'danger'} title={'Work order is on-hold'} style={{ marginBottom: 10 }} />
        ) : (
          <></>
        ),
      ]}
      headerExtras={[
        <CreateLinkedWorkOrder
          record={record}
          isDisabled={['WorkOrderStageCancelled', 'WorkOrderStageDone'].includes(
            String(record?.stage?.key),
          )}
        />,
        <BookingModal
          launcherType={'BUTTON'}
          recordId={record?.id}
          scheduleType={recordTypeForBookingModal()}
        />,
        <WorkOrderEmailActionButton record={record} />,
      ]}
      customTabs={[
        {
          key: 'Summary',
          tab: 'Summary',
        },
        {
          key: 'WorkOrderHistory',
          tab: 'Work Order History',
        },
      ]}
      customTabBodies={{
        Summary: (
          <div>
            <CasesPendingCallout record={record} hideEmpty={true} />

            <div style={{ padding: '0px 2px', marginTop: 5, marginBottom: 15 }}>
              <WorkOrderPendingCallout record={record} hideEmpty={true} />
            </div>

            {['INSTALL', 'SERVICE', 'PRE_PULL', 'INSTALL_BUSINESS'].includes(
              String(record?.type),
            ) && (
              <div style={{ marginBottom: 15 }}>
                {permissionToFailList?.length > 0 ? (
                  <Callout
                    intent={permissionToFailList?.length > 0 ? 'warning' : 'success'}
                    title={'Permission To Fail'}
                  >
                    <div style={{ marginTop: permissionToFailList.length > 0 ? 10 : 0 }}>
                      {permissionToFailList?.map((record) => (
                        <div>
                          <Link target="_blank" to={getBrowserPath(record)}>
                            <div>
                              #{record.recordNumber} (
                              {getProperty(record, 'ApprovalStatus') || 'Pending Review'})
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </Callout>
                ) : (
                  <> </>
                )}
              </div>
            )}

            {['REMEDIATION', 'INSTALL', 'INSTALL_BUSINESS'].includes(String(record?.type)) && (
              <div style={{ marginBottom: 15 }}>
                {remediationRequiredReasons?.length > 0 ? (
                  <Callout
                    intent="primary"
                    title={
                      remediationRequiredReasons?.length > 0
                        ? 'Remediation Forms'
                        : 'No remediation forms'
                    }
                  >
                    <div style={{ marginTop: remediationRequiredReasons.length > 0 ? 10 : 0 }}>
                      {remediationRequiredReasons?.map((record) => (
                        <div>
                          <Link target="_blank" to={getBrowserPath(record)}>
                            <div>
                              #{record.recordNumber} {getProperty(record, 'Reason')}
                            </div>
                          </Link>
                          <div>{getProperty(record, 'OtherInformation')}</div>
                        </div>
                      ))}
                    </div>
                  </Callout>
                ) : (
                  <> </>
                )}
              </div>
            )}

            {showONTApprovalList()}
            {showChangeReasons()}

            <div style={{ marginBottom: 15 }}>
              <DetailsConfigurator record={record} viewConfiguration={viewConfigurations[0]} />
            </div>

            {['INSTALL', 'SERVICE', 'INSTALL_BUSINESS'].includes(String(record?.type)) && (
              <div style={{ marginBottom: 15 }}>
                <OrderNetworkItems
                  disableCheckAll={true}
                  record={order!}
                  workOrderRecord={record!}
                />
              </div>
            )}

            <Section title="Notes" style={{ marginBottom: 10 }}>
              <SectionCard>
                <NoteFeed record={record} includeRelatedEntities={['WorkOrder']} />
              </SectionCard>
            </Section>
          </div>
        ),
        WorkOrderHistory: (
          <div>
            <WorkOrderHistory workOrderId={record.id} />
          </div>
        ),
      }}
      leftColumn={[
        <AssociatedWorkOrdersCard sourceRecord={record!} />,
        <RecordCard
          showRecordStage
          showActionMenu
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={ORDER_MODULE}
          associatedRecordEntityName={ORDER}
          visibleProperties={['ContractStartDate', 'ContractEndDate']}
        />,
        <RecordCard
          shouldPollData={true}
          pollingIntervalSeconds={5}
          customTitle={<span>Appointment</span>}
          showActionMenu
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={FIELD_SERVICE_MODULE}
          associatedRecordEntityName={SERVICE_APPOINTMENT}
          visibleProperties={['Type', 'Date', 'TimeBlock']}
        />,
        <RecordCard
          showActionMenu
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={CRM_MODULE}
          associatedRecordEntityName={ADDRESS}
          visibleProperties={[
            'Type',
            'SalesStatus',
            'ExPolygonId',
            'L1PolygonId',
            'L2PolygonId',
            'L4PolygonId',
            'L4ClosureId',
            'SubClassification',
          ]}
        />,
        <RecordCard
          showActionMenu
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={CRM_MODULE}
          associatedRecordEntityName={CONTACT}
          visibleProperties={['EmailAddress', 'Phone', 'Mobile']}
        />,
        <AssociationDataTable
          thumbnailSize={12}
          title="Files"
          record={record}
          showFileCategoryForType="DEFAULT"
          moduleName="SchemaModule"
          entityName="File"
          location="sidebar"
          collapsedByDefault
        />,
      ]}
    />
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  sendConfirmation: (payload: any) => dispatch(sendConfirmationEmail(payload)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(WorkOrderDetailView);
