import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import { Button } from '@blueprintjs/core';
import { DbRecordAssociationRecordsTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { Dropdown, Menu, Popconfirm } from 'antd';
import fileDownload from 'js-file-download';
import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import CreateContactModal from '../../../../containers/CrmModule/containers/Contact/CreateContact';
import GenerateWorkOrderFromWorkOrder from '../../../../containers/FieldServiceModule/containers/WorkOrder/GenerateWorkOrderFromWorkOrder';
import OfferProductSelector from '../../../../containers/OrderModule/containers/Order/AddProducts/OfferProductSelector';
import SplitOrder from '../../../../containers/OrderModule/containers/Order/SplitOrder';
import PriceBookProductSelector from '../../../../containers/ProductModule/AddProductLegacy/PriceBookProductSelector';
import {
  canUserCreateRecord,
  hasAnyRoles,
  hasPermissions,
} from '../../../../shared/permissions/rbacRules';
import { displayMessage } from '../../../../shared/system/messages/store/reducers';
import history from '../../../../shared/utilities/browserHistory';
import { checkRecordIsLocked } from '../../../../shared/utilities/recordHelpers';
import {
  getElasticSearchKeysFromSchemaColumn,
  getSchemaFromShortListBySchemaId,
} from '../../../../shared/utilities/schemaHelpers';
import BookingModal from '../../../appointments/components/BookingModal';
import Flow from '../../../flow/Flow';
import { listUsers } from '../../../identity/store/actions';
import { getPipelinesByModuleAndEntity } from '../../../pipelines/store/actions';
import CoreForm from '../../../records/components/Forms/CoreForm';
import { initializeRecordForm } from '../../../records/components/Forms/store/actions';
import { submitNAOrder } from '../../../records/components/RecordHeader/NetworkAdjustmentActions/actions';
import { getRecordByIdRequest, IGetRecordById } from '../../../records/store/actions';
import {
  CREATE_DB_RECORD_REQUEST,
  UPDATE_DB_RECORD_BY_ID_REQUEST,
} from '../../../records/store/constants';
import { IRecordReducer } from '../../../records/store/reducer';
import { getSchemaByIdRequest, ISchemaById } from '../../../schemas/store/actions';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import {
  ISetOrderBuilderDrawerRecordIds,
  openAddProductDrawer,
  openOrderBuilderDrawer,
  setOrderBuilderDrawerRecordIds,
} from '../../../userInterface/store/actions';
import { IOpenAddProductDrawer } from '../../../userInterface/store/types';
import {
  createOrderFromAccountVisible,
  ILeadWorkflow,
  updateLeadWorkflow,
} from '../../../workflow/store/actions';
import {
  exportAssociationRecordsRequest,
  getRecordAssociationsRequest,
  IExportAssociationRecords,
  IGetRecordAssociations,
} from '../../store/actions';
import { IRecordAssociationsReducer } from '../../store/reducer';
import LookUpDrawer from '../Lookup/LookUpDrawer';
import LookUpCreateModal from '../LookUpCreateModal';

const LOOKUP_AND_CREATE = 'LOOKUP_AND_CREATE';
const CREATE_ONLY = 'CREATE_ONLY';
const LOOKUP_ONLY = 'LOOKUP_ONLY';

const { ORDER_MODULE } = SchemaModuleTypeEnums;
const { SERVICE_APPOINTMENT, PAYMENT_METHOD, PRODUCT } = SchemaModuleEntityTypeEnums;

interface Props {
  record: DbRecordEntityTransform;
  relation: DbRecordAssociationRecordsTransform;
  recordAssociationReducer: IRecordAssociationsReducer;
  schemaType?: SchemaTypeEntity;
  hidden?: string[];
  userReducer: any;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  getSchema: any;
  initializeForm: any;
  getAssociations: any;
  getRecordById: any;
  getUsers: any;
  getPipelines: any;
  isCreateHidden?: boolean;
  customActionOverride?: boolean;
  exportAssociationRecords: any;
  alertMessage: any;
  createOrderVisible: () => void;
  updateLeadWorkflow: (params: ILeadWorkflow) => void;
  isEditDisabled?: boolean;
  customIcon?: JSX.Element;
  borderlessButton?: boolean;
  compact?: boolean;
  onActionSuccess?: Function;
  customText?: string;
  openAddProduct: Function;
  openOrderBuilder: () => void;
  setOrderBuilderDrawerRecordIds: (params: ISetOrderBuilderDrawerRecordIds) => void;
  paymentMethodAddressId?: string;
}

interface State {
  uuid: string;
  createContactVisible: boolean;
  schema: any;
  excludeFromCreate: string | undefined;
  associatingRecordId: string | undefined;
  associatingRecordEntity: string | undefined;
  createBroadbandVisible: boolean;
  swapCustomerDeviceOntVisible: boolean;
  createdNetworkAdjustmentId: string | undefined;
}

const addressParentAssociationsLabels = ['Contact__Address', 'Account__Address', 'Lead__Address'];

class ListActionMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      uuid: uuidv4(),
      createContactVisible: false,
      schema: {},
      excludeFromCreate: undefined,
      associatingRecordId: undefined,
      associatingRecordEntity: undefined,
      createBroadbandVisible: false,
      swapCustomerDeviceOntVisible: false,
      createdNetworkAdjustmentId: undefined,
    };
  }

  onActionSuccess = () => {
    if (this.props.onActionSuccess) {
      this.props.onActionSuccess();
    }
  };

  private fetchCreatedNetworkAdjustmentId = (recordId: string) => {
    if (recordId) {
      this.setState({ createdNetworkAdjustmentId: recordId });
    }
  };

  private startOrderBuilder = (recordId: string) => {
    const { openOrderBuilder, setOrderBuilderDrawerRecordIds } = this.props;
    setOrderBuilderDrawerRecordIds({
      accountId: recordId,
    });
    openOrderBuilder();
  };

  private submitNAOrderOnFlowFinish = () => {
    const { alertMessage } = this.props;
    if (this.state.createdNetworkAdjustmentId) {
      submitNAOrder(this.state.createdNetworkAdjustmentId!, alertMessage);
    }
  };

  private async initializeCreateForm() {
    const {
      initializeForm,
      getSchema,
      relation,
      record,
      getUsers,
      getPipelines,
      schemaType,
      createOrderVisible,
    } = this.props;

    getUsers();

    getSchema({ schemaId: relation?.schema?.id }, (result: SchemaEntity) => {
      getPipelines({ schema: result });

      if (record.entity === 'CrmModule:Account' && result.entityName === 'Contact') {
        this.setState({
          schema: result,
          createContactVisible: true,
          excludeFromCreate: 'Account',
          associatingRecordId: record.id,
          associatingRecordEntity: record.entity,
        });
      } else if (record.entity === 'CrmModule:Address' && result.entityName === 'Contact') {
        this.setState({
          schema: result,
          createContactVisible: true,
          excludeFromCreate: 'Premise',
          associatingRecordId: record.id,
          associatingRecordEntity: record.entity,
        });
      } else if (
        record.entity === 'OrderModule:OrderItem' &&
        result.entityName === 'CustomerDeviceOnt'
      ) {
        this.setState({
          schema: result,
          createBroadbandVisible: true,
          associatingRecordId: record.id,
          associatingRecordEntity: record.entity,
        });
      } else if (record.entity === 'CrmModule:Account' && result.entityName === 'Order') {
        createOrderVisible();
      } else {
        const hasDefaultType = result?.types?.some(
          (schema: SchemaTypeEntity) => schema.name === 'DEFAULT',
        );

        initializeForm({
          formUUID: this.state.uuid,
          title: `Create ${relation?.schema?.entityName}`,
          showFormModal: true,
          isCreateReq: true,
          schema: result,
          selected: null,
          recordType: schemaType?.name || (hasDefaultType ? 'DEFAULT' : undefined),
          sections: [
            {
              name: result.name,
              schema: result,
              associations: [
                {
                  entity: record.entity,
                  recordId: record.id,
                  title: record.title,
                  recordNumber: record.recordNumber,
                  schemaAssociationId: relation.schemaAssociationId,
                },
              ],
            },
          ],
          modified: [
            {
              schemaId: relation?.schema?.id,
              associations: [
                {
                  entity: record.entity,
                  recordId: record.id,
                  // if this is a child relation then we are associating the PARENT otherwise if it
                  // is a parent relation then we are associating the CHILD
                  relationType:
                    relation?.schemaAssociation?.relationType === 'child'
                      ? RelationTypeEnum.PARENT
                      : RelationTypeEnum.CHILD,
                },
              ],
            },
          ],
        });
      }
    });
  }

  private initializeCreateLaedFromAddressForm() {
    const { updateLeadWorkflow, record } = this.props;
    updateLeadWorkflow({
      relatedRecord: record,
    });
  }

  private exportRecords() {
    const { record, relation, exportAssociationRecords, alertMessage, schemaReducer } = this.props;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    const relationSchema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      relation?.schema?.id,
    );

    if (!relationSchema?.columns) {
      return;
    }
    const defaultColumns = getElasticSearchKeysFromSchemaColumn(relationSchema, undefined);
    const userFields = [
      'id', // adding id to export - d19-61
      'title',
      'recordNumber',
      'createdAt',
      'updatedAt',
      ...defaultColumns?.map((column) => column.dataIndex),
    ];

    let nestedEntities: string | undefined = undefined;
    if (relation.schema.entityName === 'CustomerDeviceOnt') {
      nestedEntities = 'Address';
      userFields.push('Address.dbRecords.properties.FullAddress');
    }

    exportAssociationRecords(
      {
        recordId: record.id,
        schema,
        entity: relation.schema.entityName,
        userFields: userFields.join(),
        nestedEntities,
      },
      (resp: any) => {
        if (resp) {
          fileDownload(resp.results, `${schema ? schema.entityName : 'data'}.csv`);
          alertMessage({ body: 'Records exported.', type: 'success' });
        }
      },
    );
  }

  private renderActions() {
    // Render diff actions based on the record , related record
    const { relation, record, userReducer, isEditDisabled } = this.props;

    if (isEditDisabled) {
      return;
    }

    // Disable any action with related entities if record is locked
    if (
      checkRecordIsLocked(record) &&
      !hasAnyRoles(userReducer, 'system.admin', 'BillingModuleAdmin') &&
      !['Invoice__CreditNote'].includes(relation?.schemaAssociation.label as string)
    ) {
      return;
    }

    if (relation?.schemaAssociation?.relationType === RelationTypeEnum.PARENT) {
      return this.renderChildActions();
    }

    if (relation?.schemaAssociation?.relationType === RelationTypeEnum.CHILD) {
      return this.renderParentActions();
    }
  }

  private renderParentActions() {
    const { userReducer, record, relation, hidden, customActionOverride } = this.props;

    // There are necessary overrides for the record type,
    // when doing the schedule filtering for the booking modal.
    const recordTypeForBookingModal = () => {
      if (record?.type === 'INSTALL_BUSINESS') {
        return 'INSTALL';
      } else {
        return record?.type;
      }
    };

    if (customActionOverride) {
      return (
        <LookUpCreateModal
          record={record}
          relation={relation}
          onLookupSuccess={this.onActionSuccess}
        />
      );
    }

    if (relation?.schemaAssociation.label === 'OrderItem__Product') {
      console.log('debug: RELATION', relation);
    }

    switch (relation?.schemaAssociation?.parentActions) {
      // Todo: This needs to be updated.
      case LOOKUP_AND_CREATE:
        if (relation?.schema?.entityName === SERVICE_APPOINTMENT) {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item className="list-action-menu-item">
                    <BookingModal
                      onBookingSuccess={this.onActionSuccess}
                      recordId={record?.id}
                      hidden={hidden}
                      launcherType="MENU_ITEM"
                      scheduleType={recordTypeForBookingModal() || 'INSTALL'}
                    />
                  </Menu.Item>
                  {relation.dbRecords ? (
                    <></>
                  ) : (
                    <Menu.Item
                      disabled={
                        !hasPermissions(userReducer, [
                          'fieldservicemodule.serviceappointment.override',
                        ])
                      }
                      onClick={() => this.initializeCreateForm()}
                    >
                      Create New
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        } else if (
          relation.schema.entityName === 'Product' &&
          relation.schema.moduleName === 'ProductModule'
        ) {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item className="list-action-menu-item">
                    <OfferProductSelector
                      record={record}
                      relation={relation}
                      relatedProductUpdate
                      onSuccess={this.onActionSuccess}
                    />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        } else if (
          addressParentAssociationsLabels.includes(relation?.schemaAssociation?.label as string)
        ) {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item className="list-action-menu-item">
                    <LookUpCreateModal
                      record={record}
                      relation={relation}
                      onLookupSuccess={this.onActionSuccess}
                    />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        } else {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item
                    disabled={!canUserCreateRecord(userReducer, relation.schema)}
                    onClick={() => this.initializeCreateForm()}
                  >
                    Create
                  </Menu.Item>
                  <Menu.Item className="list-action-menu-item">
                    <LookUpDrawer
                      customText={this.props.customText}
                      record={record}
                      relation={relation}
                      onLookupSuccess={this.onActionSuccess}
                    />
                  </Menu.Item>
                  {this.renderExportAction()}
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        }

      case LOOKUP_ONLY:
        if (relation?.schema?.entityName === SERVICE_APPOINTMENT) {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item className="list-action-menu-item">
                    <BookingModal
                      onBookingSuccess={this.onActionSuccess}
                      recordId={record?.id}
                      hidden={hidden}
                      launcherType="MENU_ITEM"
                      scheduleType={recordTypeForBookingModal() || 'INSTALL'}
                    />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <DownOutlined />}
              />
            </Dropdown>
          );
        } else if (relation?.schemaAssociation?.label === 'Order__OrderItem') {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item className="list-action-menu-item">
                    <OfferProductSelector
                      record={record}
                      relation={relation}
                      onSuccess={this.onActionSuccess}
                    />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <DownOutlined />}
              />
            </Dropdown>
          );
        } else if (relation?.schemaAssociation?.label === 'PriceBook__Product') {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item>
                    <LookUpDrawer
                      customText={this.props.customText}
                      onLookupSuccess={this.onActionSuccess}
                      record={record}
                      relation={relation}
                      hidden={hidden}
                    />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <DownOutlined />}
              />
            </Dropdown>
          );
        } else if (relation?.schemaAssociation?.label === 'Offer__Product') {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item className="list-action-menu-item">
                    <PriceBookProductSelector record={record} relation={relation} />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button small={this.props.compact} icon={this.props.customIcon || <DownOutlined />} />
            </Dropdown>
          );
        } else if (relation?.schemaAssociation?.label === 'Invoice__InvoiceItem') {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item
                    onClick={() => {
                      this.props.openAddProduct({
                        parentRecord: record,
                      });
                    }}
                  >
                    <span>Add Product</span>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <CaretDownOutlined />}
              />
            </Dropdown>
          );
        } else if (relation?.schemaAssociation?.label === 'Feature__Product') {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item className="list-action-menu-item">
                    <LookUpDrawer
                      customText={this.props.customText}
                      onLookupSuccess={this.onActionSuccess}
                      record={record}
                      relation={relation}
                      hidden={hidden}
                    />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <DownOutlined />}
              />
            </Dropdown>
          );
        } else if (relation?.schema?.entityName === PRODUCT) {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item className="list-action-menu-item">
                    <PriceBookProductSelector record={record} relation={relation} />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        } else {
          if (customActionOverride) {
            return (
              <LookUpCreateModal
                record={record}
                relation={relation}
                onLookupSuccess={this.onActionSuccess}
              />
            );
          } else if (
            addressParentAssociationsLabels.includes(relation?.schemaAssociation?.label as string)
          ) {
            return (
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu className="list-action-menu">
                    <Menu.Item className="list-action-menu-item">
                      <LookUpCreateModal
                        record={record}
                        relation={relation}
                        onLookupSuccess={this.onActionSuccess}
                      />
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  small={this.props.compact}
                  style={{
                    border: this.props.borderlessButton ? 'none' : 'auto',
                  }}
                  icon={
                    this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />
                  }
                />
              </Dropdown>
            );
          } else {
            return (
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu className="list-action-menu">
                    <Menu.Item className="list-action-menu-item">
                      <LookUpDrawer
                        customText={this.props.customText}
                        onLookupSuccess={this.onActionSuccess}
                        record={record}
                        relation={relation}
                        hidden={hidden}
                      />
                    </Menu.Item>
                    {this.renderExportAction()}
                  </Menu>
                }
              >
                <Button
                  small={this.props.compact}
                  style={{
                    border: this.props.borderlessButton ? 'none' : 'auto',
                  }}
                  icon={
                    this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />
                  }
                />
              </Dropdown>
            );
          }
        }
      case CREATE_ONLY:
        if (relation?.schemaAssociation?.label === 'Order__SplitOrder') {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item className="list-action-menu-item">
                    <SplitOrder record={record} />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button icon={this.props.customIcon || <DownOutlined />} />
            </Dropdown>
          );
        } else if (relation?.schemaAssociation?.label === 'Order__Invoice') {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item
                    disabled={!hasPermissions(userReducer, ['billingmodule.invoice.create'])}
                    onClick={() => history.push(`/OrderModule/Order/${record?.id}/invoice`)}
                  >
                    Create Invoice
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        } else if (relation?.schemaAssociation?.label === 'WorkOrder__Invoice') {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item
                    key="woInvoiceConfirm"
                    disabled={
                      record?.type !== 'SERVICE' ||
                      !hasPermissions(userReducer, ['billingmodule.invoice.create'])
                    }
                  >
                    <Popconfirm
                      disabled={
                        record?.type !== 'SERVICE' ||
                        !hasPermissions(userReducer, ['billingmodule.invoice.create'])
                      }
                      title="Are you sure want to create an invoice from the work order?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() =>
                        history.push(`/FieldServiceModule/WorkOrder/${record?.id}/invoice`)
                      }
                    >
                      Create Invoice
                    </Popconfirm>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        } else if (relation?.schemaAssociation?.label === 'Account__Order') {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item
                    disabled={!canUserCreateRecord(userReducer, relation.schema)}
                    onClick={() => this.startOrderBuilder(record?.id)}
                  >
                    Create Order
                  </Menu.Item>
                  {this.renderExportAction()}
                </Menu>
              }
            >
              <Button
                small
                style={{ border: this.props.borderlessButton ? 'none' : 'auto' }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        } else {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item
                    disabled={!canUserCreateRecord(userReducer, relation.schema)}
                    onClick={() => this.initializeCreateForm()}
                  >
                    Create New
                  </Menu.Item>
                  {this.renderExportAction()}
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{ border: this.props.borderlessButton ? 'none' : 'auto' }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        }
      default:
        return <></>;
    }
  }

  private renderExportAction() {
    const { relation, recordAssociationReducer } = this.props;

    if (relation?.schemaAssociation?.label === 'NetworkDevice__CustomerDeviceOnt') {
      return (
        <Menu.Item
          disabled={recordAssociationReducer.isExporting}
          onClick={() => this.exportRecords()}
        >
          Export
        </Menu.Item>
      );
    }
  }

  private renderChildActions() {
    const { userReducer, record, relation, hidden, recordReducer } = this.props;
    const { schemaAssociation } = relation;

    switch (schemaAssociation.childActions) {
      case LOOKUP_AND_CREATE:
        if (['Lead__Address'].includes(relation.schemaAssociation.label as string)) {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item
                    disabled={!canUserCreateRecord(userReducer, relation.schema)}
                    onClick={() => this.initializeCreateLaedFromAddressForm()}
                  >
                    Create New
                  </Menu.Item>
                  <Menu.Item className="list-action-menu-item">
                    <LookUpDrawer
                      customText={this.props.customText}
                      record={record}
                      relation={relation}
                      onLookupSuccess={this.onActionSuccess}
                    />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        } else if (
          ['OpenreachNetworkAdjustment__Feature'].includes(
            relation.schemaAssociation.label as string,
          )
        ) {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Flow
                    launchInterface="MENU_ITEM"
                    launchInterfaceTitle="Create"
                    flowSteps={[
                      {
                        flowType: 'RECORD',
                        title: 'Create Network Adjustment',
                        moduleName: 'ProjectModule',
                        entityName: 'OpenreachNetworkAdjustment',
                        associationEntity: record?.entity,
                        associationId: record?.id,
                        runOnSubmit: this.fetchCreatedNetworkAdjustmentId,
                      },
                      {
                        flowType: 'ASSOCIATION',
                        title: 'Add Products to Network Adjustment',
                        moduleName: 'ProjectModule',
                        entityName: 'OpenreachNetworkAdjustmentLineItem',
                        relateRecordFromStep: [1],
                        nextOrSubmitButtonTitle: 'Submit Order',
                        runOnSubmit: this.submitNAOrderOnFlowFinish,
                      },
                    ]}
                  />
                  <Menu.Item className="list-action-menu-item">
                    <LookUpDrawer
                      customText={this.props.customText}
                      record={record}
                      relation={relation}
                      onLookupSuccess={this.onActionSuccess}
                    />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        } else {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item
                    disabled={!canUserCreateRecord(userReducer, relation.schema)}
                    onClick={() => this.initializeCreateForm()}
                  >
                    Create New
                  </Menu.Item>
                  <Menu.Item className="list-action-menu-item">
                    <LookUpDrawer
                      customText={this.props.customText}
                      record={record}
                      relation={relation}
                      onLookupSuccess={this.onActionSuccess}
                    />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        }

      case LOOKUP_ONLY:
        return (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu className="list-action-menu">
                <Menu.Item className="list-action-menu-item">
                  <LookUpDrawer
                    customText={this.props.customText}
                    onLookupSuccess={this.onActionSuccess}
                    record={record}
                    relation={relation}
                    hidden={hidden}
                  />
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              small={this.props.compact}
              style={{ border: this.props.borderlessButton ? 'none' : 'auto' }}
              icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
            />
          </Dropdown>
        );
      case CREATE_ONLY:
        // WorkOrder -> WorkOrder
        if (relation?.schemaAssociation?.label === 'WorkOrder__WorkOrder') {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item className="list-action-menu-item">
                    <GenerateWorkOrderFromWorkOrder record={record} />
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{
                  border: this.props.borderlessButton ? 'none' : 'auto',
                }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        } else {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu className="list-action-menu">
                  <Menu.Item
                    disabled={!canUserCreateRecord(userReducer, relation.schema)}
                    onClick={() => this.initializeCreateForm()}
                  >
                    Create New
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                small={this.props.compact}
                style={{ border: this.props.borderlessButton ? 'none' : 'auto' }}
                icon={this.props.customIcon || <CaretDownOutlined style={{ fontSize: '0.9em' }} />}
              />
            </Dropdown>
          );
        }

      default:
        return <></>;
    }
  }

  private handleFormSubmit(params: { event: string; res: any }) {
    switch (params.event) {
      case CREATE_DB_RECORD_REQUEST:
        this.fetchAssociations();
        break;

      case UPDATE_DB_RECORD_BY_ID_REQUEST:
        this.fetchAssociations();
        break;
    }
  }

  private fetchAssociations() {
    const { getAssociations, record, relation, schemaReducer, getRecordById } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    if (record) {
      if (record && schema) {
        getRecordById({ schema, recordId: record.id });
        getAssociations({
          recordId: record.id,
          key: relation.schema.entityName,
          schema: schema,
          entities: [relation.schema.entityName],
        });
      }
      return <div>data fetched</div>;
    }
  }

  render() {
    return (
      <>
        <CreateContactModal
          associatingRecordId={this.state.associatingRecordId}
          associatingRecordEntity={this.state.associatingRecordEntity}
          excludeFromCreate={this.state.excludeFromCreate}
          visible={this.state.createContactVisible}
          schema={this.state.schema}
          passDataToParent={(e: any) => {
            this.setState({ createContactVisible: e });
          }}
        />
        <CoreForm
          type="MODAL"
          formUUID={this.state.uuid}
          onSubmitEvent={(params: { event: string; res: any }) => {
            this.handleFormSubmit(params);
            this.onActionSuccess();
          }}
        />

        <div style={{ display: 'flex' }}>{this.renderActions()}</div>
      </>
    );
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  openAddProduct: (payload: IOpenAddProductDrawer) => dispatch(openAddProductDrawer(payload)),
  getUsers: (cb: any) => dispatch(listUsers(cb)),
  getPipelines: (params: { schema: SchemaEntity }) =>
    dispatch(getPipelinesByModuleAndEntity(params)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  getSchema: (payload: ISchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations) =>
    dispatch(getRecordAssociationsRequest(params)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  exportAssociationRecords: (params: IExportAssociationRecords, cb: () => {}) =>
    dispatch(exportAssociationRecordsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  createOrderVisible: () => dispatch(createOrderFromAccountVisible()),
  updateLeadWorkflow: (params: ILeadWorkflow) => dispatch(updateLeadWorkflow(params)),
  openOrderBuilder: () => dispatch(openOrderBuilderDrawer()),
  setOrderBuilderDrawerRecordIds: (params: ISetOrderBuilderDrawerRecordIds) =>
    dispatch(setOrderBuilderDrawerRecordIds(params)),
});

export default connect(mapState, mapDispatch)(ListActionMenu);
