import React, { useContext } from 'react';
import { connect } from 'react-redux';

import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../../../core/userInterface/store/actions';

import { displayMessage } from '../../../../../../../shared/system/messages/store/reducers';

import { SupportDashboardContext } from '../../index';
import { OdinSearchProvider } from './context/provider';
import CaseListView from './CaseListView';
import './styles.scss';

interface Props {
  alertMessage: (params: { body: string; type: string }) => void;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const CaseManagementListView: React.FC<Props> = (props: Props) => {
  const { caseSchema } = useContext(SupportDashboardContext);

  return (
    <OdinSearchProvider schema={caseSchema}>
      <CaseListView {...props} />
    </OdinSearchProvider>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(CaseManagementListView);
