import React, { useEffect, useState } from 'react';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import { httpPost } from '../../../../../../../../shared/http/requests';
import OdinTable from '../../../../../../../shared/components/OdinTable';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '../../../../../../../../shared/utilities/recordHelpers';
import { getCaseContactFromRecord } from '../../../helpers';
import { toSentenceCase } from '../../../../../../../../shared/utilities/stringHelpers';
import { Button, Tooltip } from '@blueprintjs/core';
import CommunicationsStatusTag from '../../../../MyCasesViewV2/components/CommunicationsStatusTag';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { displayMessage } from '../../../../../../../../shared/system/messages/store/reducers';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '../../../../../../../../core/userInterface/store/actions';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import dayjs from 'dayjs';

const uuid = uuidv4();
const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const CASE = 'Case';

interface Props {
  user: DbRecordEntityTransform;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const UserCasesTable: React.FC<Props> = (props: Props) => {
  const { user, openDrawer } = props;
  const [caseList, setCaseList] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Get the user's cases
  useEffect(() => {
    getCaseList();
  }, [user]);

  // Search 2.0
  const getCaseList = async () => {
    const query = {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'OwnerId',
            operator: 'eq',
            value: user.id,
          },
          {
            columnName: 'StageName',
            operator: 'in',
            value: ['Open', 'Blocked', 'Pending Reply', 'Pending Agent', 'Pending Review'],
          },
        ],
        returnProperties: [
          'id',
          'title',
          'recordNumber',
          'createdAt',
          'stage',
          'entity',
          'caseTeam',
          'caseOwner',
          'caseContact',
          'properties.OwnerId',
          'properties.TeamId',
          'properties.ContactId',
          'properties.Category',
          'properties.Source',
          'properties.Channel',
        ],
        sort: {
          createdAt: {
            order: 'asc',
          },
        },
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 15,
      },
    };
    httpPost(`SupportModule/v2.0/records/search`, query).then((res) => {
      const records = res?.data?.data?.records || [];
      setCaseList(records);
      setIsLoading(false);
    });
  };

  // Table data for the user's cases
  const tableData: any[] = caseList.map((item: DbRecordEntityTransform) => {
    console.log('debug: UserCasesTable tableData item', item);
    return {
      key: item.id,
      caseNumber: (
        <Link target="_blank" key={item.id} to={getBrowserPath(item)}>
          {item.recordNumber}
        </Link>
      ),
      contact: (
        <Row>
          <Col span={20}>{getCaseContactFromRecord(item)}</Col>
          <Col span={4}>
            {item.caseContact?.length! > 0 ? (
              <Link to={`/CrmModule/Contact/${item.caseContact[0].id}`} target="_blank">
                <Button
                  small
                  intent="primary"
                  minimal
                  icon={<i className="bi bi-box-arrow-up-right" />}
                />
              </Link>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      ),
      age: (
        <Tooltip
          hoverOpenDelay={1000}
          position="top"
          content={String(dayjs(item.createdAt).format('DD/MM/YYYY HH:mm:ss'))}
        >
          <span style={{ cursor: 'pointer' }} key={`age${item.id}`}>
            {dayjs(item.createdAt).fromNow()}
          </span>
        </Tooltip>
      ),
      source: `${
        getProperty(item, 'Source') ? toSentenceCase(getProperty(item, 'Source')) : 'Unknown'
      } → ${
        getProperty(item, 'Channel') ? toSentenceCase(getProperty(item, 'Channel')) : 'Unknown'
      }`,
      category: getProperty(item, 'Category') || '',
      SLA: getProperty(item, 'SLA') || '',

      status: (
        <Row align="middle" justify="center">
          <Col>
            <CommunicationsStatusTag status={item.stage?.name} />
          </Col>
        </Row>
      ),
      action: (
        <Row align="middle" justify="end">
          <Col>
            <Button
              key={item.id}
              intent="primary"
              minimal
              onClick={() => {
                openDrawer({
                  recordId: item.id,
                  moduleName: SUPPORT_MODULE,
                  entityName: CASE,
                });
              }}
              icon={<i className="bi bi-eye" />}
            />
          </Col>
        </Row>
      ),
    };
  });

  return (
    <Row style={{ paddingLeft: 45, marginBottom: caseList.length > 0 ? 20 : 0 }}>
      <Col span={24}>
        {!isLoading && caseList.length > 0 ? (
          <OdinTable
            height="auto"
            data={tableData}
            columns={[
              {
                key: 'caseNumber',
                title: 'Case #',
                width: 3,
              },
              {
                key: 'contact',
                title: 'Contact',
                width: 4,
              },
              {
                key: 'age',
                title: 'Age',
                width: 3,
              },
              {
                key: 'source',
                title: 'Source',
                width: 4,
              },
              {
                key: 'category',
                title: 'Category',
                width: 4,
              },
              {
                key: 'SLA',
                title: 'SLA',
                width: 2,
                align: 'center',
              },
              {
                key: 'status',
                title: 'Status',
                width: 3,
                align: 'center',
              },
              {
                key: 'action',
                title: '',
                align: 'right',
                width: 1,
              },
            ]}
          />
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(UserCasesTable);
