import { OdinSearchParams } from "./types";

const getKeyName = (schemaId: string) => `odin_filters_${schemaId}`;

export const loadAllParams = (schemaId: string) => {
  const allParamsString = localStorage.getItem(getKeyName(schemaId)) || '[]';
  const allParams = JSON.parse(allParamsString) as OdinSearchParams[];
  return allParams;
};

export const saveParams = (schemaId: string, params: OdinSearchParams) => {
  const allParams = loadAllParams(schemaId);
  const existingParamsIndex = allParams.findIndex(
    (p) => p.name === params.name || JSON.stringify(p.filters) === JSON.stringify(params.filters),
  );

  const newAllParams =
    existingParamsIndex === -1
      ? [...allParams, params]
      : allParams.map(
        (p, index) =>
          (index === existingParamsIndex
            ? { ...params, selected: true }
            : { ...p, selected: false }
          )
      );

  const allParamsString = JSON.stringify(newAllParams);
  localStorage.setItem(getKeyName(schemaId), allParamsString);
  return newAllParams;
  // setSavedFilters(newAllParams);
};

export const deleteParams = (schemaId: string, params: OdinSearchParams) => {
  const allParams = loadAllParams(schemaId);
  const newAllParams = allParams.filter((p) => p.name !== params.name);
  const allParamsString = JSON.stringify(newAllParams);
  localStorage.setItem(getKeyName(schemaId), allParamsString);
  return newAllParams;
  // setSavedFilters(newAllParams);
};