import { Background, Controls, ReactFlow } from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';

import { LinksViewProps } from '../types';
import { nodeTypes } from './CustomNodes';
import './styles.css';

const buildNodesAndEdges = (schema: SchemaEntity | undefined, links: SchemaAssociationEntity[]) => {
  const parentCount = links.filter(link => !!link.parentSchema).length;
  const childCount = links.filter(link => !!link.childSchema).length;
  const maxCount = Math.max(parentCount, childCount);
  const entrySize = 200;
  const entryMargin = 20;

  const mainNode = {
    id: String(schema?.id),
    data: { label: `${schema?.entityName}` },
    type: 'current',
    position: { x: (maxCount * entrySize / 2) - entryMargin, y: 100 },
  }

  const addLinkNode = {
    id: 'add-link',
    data: { label: 'Create New Link' },
    type: 'add',
    position: { x: mainNode.position.x + entrySize + entryMargin, y: 100 }
  }

  const addLinkEdge = {
    id: `${schema?.id}-add-link`,
    source: `${schema?.id}`,
    sourceHandle: 'add-link',
    target: 'add-link',
  }

  let currentParent = 0;
  let currentChild = 0;
  const linkNodes = links.map(link => {
    const isParent = !!link.parentSchema;

    const id = link.id;

      const label = isParent
        ? `${link.parentSchema?.entityName}`
        : `${link.childSchema?.entityName}`;

    return {
      id,
      data: { label },
      type: isParent ? 'parent' : 'child',
      position: isParent
        ? { x: (currentParent++ * entrySize) + entryMargin, y: 0 }
        : { x: (currentChild++ * entrySize) + entryMargin, y: 200 }
    };
  });

  const linkEdges = links.map(link => {
    const isParent = !!link.parentSchema;

    return isParent ? {
      id: `${link.id}-${schema?.id}`,
      source: `${link.id}`,
      target: `${schema?.id}`,
    } : {
      id: `${schema?.id}-${link.id}`,
      source: `${schema?.id}`,
      target: `${link.id}`,
    }
  });

  const allNodes = [mainNode, addLinkNode, ...linkNodes];
  const allEdges = [addLinkEdge, ...linkEdges];

  return { nodes: allNodes, edges: allEdges };
};

export const DiagramView = ({ schema, links, onLinkClick, onLinkRemove }: LinksViewProps) => {
  const { nodes, edges } = buildNodesAndEdges(schema, links);

  const handleNodeClick = (event: any, node: any) => {
    if (node.id === schema?.id) return;

    const link = links.find(link => link.id === node.id);
    if (event?.target?.localName === 'div') {
      onLinkClick(link);
    } else if (event?.target?.localName === 'path' && link) {
      if (event?.target?.parentNode?.parentNode?.classList.contains('delete-icon')) {
        onLinkRemove(link);
      }
    }
  };

  return (
    <div style={{width: '100%', height: '300px'}}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodeClick={handleNodeClick}
        fitView
      >
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
}
