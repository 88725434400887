import { useState } from "react";
import { Alert, Card, CardList, Classes, Icon, InputGroup, Intent, Section, SectionCard, Tag } from "@blueprintjs/core"
import { v4 as uuidV4 } from 'uuid';

import { SchemaColumnEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity";

import { createSchemaColumnRequest, deleteSchemaColumnRequest } from "../../../../../../../../core/schemasColumns/store/actions";

import { initializeSharedForm } from "../../../../../../../../shared/components/SharedForm/store/actions";
import { displayMessage } from "../../../../../../../../shared/system/messages/store/reducers";
import FormModal, {
  FormReducerSubmitEvt,
} from '../../../../../../../../shared/components/SharedForm/SharedFormModal';

import { getErrorMessage } from "../../../../../utils/errors";

import { useAction } from "../../../../../../../../containers/ProjectModule/hooks/useAction";
import { useRequest } from "../../../../../../../../containers/ProjectModule/hooks/useRequest";

import { Empty } from "../Empty";
import { SchemaDetailsProps } from "../types";
import SchemaColumnUpdateDrawer from "./SchemaColumnUpdateDrawer";
import { getFormFields } from "./formFields";
import { SchemaColumnCreateUpdateDto } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/dto/schema.column.create.update.dto";

const uuid = uuidV4();

export const PropertiesSection = ({ schema, schemaTypeId }: SchemaDetailsProps) => {
  const [selectedColumnId, setSelectedColumnId] = useState('');
  const [columnToRemove, setColumnToRemove] = useState<SchemaColumnEntity>();
  const [isRequesting, setIsRequesting] = useState(false);
  const [search, setSearch] = useState('');
  const createProperty = useRequest(createSchemaColumnRequest);
  const deleteProperty = useRequest(deleteSchemaColumnRequest);
  const initializeForm = useAction(initializeSharedForm);
  const alertMessage = useAction(displayMessage);

  const columns = schema.columns || [];

  const filteredColumns = columns.filter(col => {
    const schemaTypeFilter = schemaTypeId === 'all' ? true : !col.schemaTypeId || col.schemaTypeId === schemaTypeId
    const nameFilter = col.name.toLocaleLowerCase().includes(search);
    return schemaTypeFilter && nameFilter;
  });

  const showPropertiesForm = (property?: SchemaColumnEntity) => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: property?.id ? 'Edit Property' : 'Create Property',
      formFields: getFormFields(schema, property, schemaTypeId === 'all' ? null : schemaTypeId),
      entityName: 'SchemaColumn',
    });  
  }

  const handleFormSubmit = async (params: FormReducerSubmitEvt) => {
    // create property
    try {
      await createProperty({
        schemaId: schema.id,
        body: params.data as SchemaColumnCreateUpdateDto,
      });
      alertMessage({
        body: 'Property created',
        type: 'success',
      });
    } catch(error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not create property. ' + message,
        type: 'error',
      });
    }
  };

  const handleDeleteProperty = async () => {
    if (!schema || !columnToRemove) return;

    setIsRequesting(true);
    await deleteProperty({
      schemaId: schema.id,
      schemaColumnId: columnToRemove.id
    });
    setIsRequesting(false);
  }

  return (
    <>
      <Section
        className="properties-section"
        title="Properties"
        rightElement={
          <>
            <InputGroup
              id="schema-properties"
              type="text"
              leftIcon="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
            <button
              className="link-button"
              onClick={() => showPropertiesForm()}
            >
              <Icon icon="add" /> New
            </button>
          </>
        }
      >
        <SectionCard padded={false} className="limited-height">
          {columns.length === 0 ? (
            <Empty icon="properties" text="No columns defined yet" />
          ) : (
            <CardList bordered={false}>
            {filteredColumns.map((column) => {
              const uniqueValidators = [...new Set(column.validators.map(validator => validator.type))];
              return (
                <Card key={column.id} interactive>
                  <span>{column.name}</span>
                  <span className="validators">
                    {uniqueValidators.map(
                      validator => (
                        <Tag
                          key={validator}
                          fill={false}
                          minimal
                        >
                          {validator}
                        </Tag>
                      )
                    )}
                  </span>
                  <Tag fill={false}>{column.type}</Tag>
                  <span className="actions">
                    <Icon
                      icon="edit"
                      className={Classes.TEXT_MUTED}
                      onClick={() => setSelectedColumnId(column.id)}
                    />
                    <Icon
                      icon="trash"
                      className={Classes.TEXT_MUTED}
                      onClick={() => setColumnToRemove(column)}
                    />
                  </span>
                </Card>
              );
            })}
            </CardList>
          )}
        </SectionCard>
      </Section>

      <FormModal
        formUUID={uuid}
        onSubmitEvent={handleFormSubmit}
      />

      {/* Drawer display is controlled by selected state */}
      <SchemaColumnUpdateDrawer
        schemaId={schema?.id}
        schemaColumnId={selectedColumnId}
        onClose={() => setSelectedColumnId('')}
      />

      {!!columnToRemove && (
        <Alert
          isOpen={true}
          canEscapeKeyCancel
          canOutsideClickCancel
          intent={Intent.DANGER}
          loading={isRequesting}
          icon="trash"
          cancelButtonText="Cancel"
          confirmButtonText="Remove"
          onClose={() => setColumnToRemove(undefined)}
          onConfirm={handleDeleteProperty}
        >
          <h3 style={{ marginTop: 0, marginBottom: "1rem" }}>Remove Property</h3>
          <p>
            Are you sure you want to remove this property?
          </p>
        </Alert>
      )}
    </>
  )
};
