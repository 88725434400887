import { Button, Dialog, DialogBody, DialogFooter, Switch } from '@blueprintjs/core';
import { FC, useState } from 'react';
import { DualPanelAssignment } from '../../../../../../shared/components/DualPanelAssignment';
import './styles.scss';

interface Props {
  team: any;
  users: any[];
}

type TUser = {
  id: string;
  name: string;
  description: string;
  status: string;
};

export const TeamsViewUsersDialog: FC<Props> = (props: Props) => {
  const { team } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<TUser[]>([]);
  const [isShowingOnlyActive, setIsShowingOnlyActive] = useState<boolean>(true);

  const closeModal = () => {
    setIsDialogOpen(false);
    setUsers([]);
  };

  const openModal = () => {
    if (props.users.length > 0) {
      const users = props.users.map((user) => ({
        id: user.id,
        name: user.name,
        description: user.email,
        status: user.status,
      }));
      setUsers(users as TUser[]);
      setIsDialogOpen(true);
    }
  };

  let filteredUsers = Object.assign([], users);

  if (isShowingOnlyActive) {
    filteredUsers = filteredUsers.filter((user: TUser) => user.status === 'ACTIVE');
  }

  return (
    <>
      <Button small minimal intent="primary" text="View" onClick={openModal} />
      <Dialog
        title="View Users in Team"
        isOpen={isDialogOpen}
        onClose={closeModal}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        style={{ width: '50%' }}
      >
        <DialogBody>
          <DualPanelAssignment
            // Left panel
            leftPanelTitle="Groups"
            leftPanelIcon="people"
            leftPanelSubtitle="All available groups in the system"
            leftPanelData={undefined}
            onLeftPanelChange={() => {}}
            // Right Panel
            rightPanelTitle={team.name || 'Team'}
            rightPanelIcon="team"
            rightPanelSubtitle="All Users in this Team"
            rightPanelData={filteredUsers}
            onRightPanelChange={() => {}}
            rightPanelLink='#users'
          />
        </DialogBody>
        <DialogFooter actions={[<Button key="Close" text="Close" onClick={closeModal} />]}>
          <Switch
            disabled={users.length === 0}
            label="Show Active users only"
            checked={isShowingOnlyActive}
            style={{ marginTop: 4 }}
            onChange={() => setIsShowingOnlyActive(!isShowingOnlyActive)}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};
