import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { MyCasesContext } from '../..';
import MyCasesListItem from './MyCaseListItem';
import './styles.scss';

interface Props {
  schema: SchemaEntity;
}

const MyCasesList: React.FC<Props> = (props: Props) => {
  const { state } = useContext(MyCasesContext);
  const { schema } = props;

  const stageOrder: string[] = [
    'Pending Agent',
    'Open',
    'Pending Reply',
    'Blocked',
    'Pending Review',
  ];

  const compareStages = (a: any, b: any) => {
    const indexA = stageOrder.indexOf(a.stage?.name);
    const indexB = stageOrder.indexOf(b.stage?.name);

    // If stage name is not in the order list, place it at the end
    const orderA = indexA !== -1 ? indexA : stageOrder.length;
    const orderB = indexB !== -1 ? indexB : stageOrder.length;

    return orderA - orderB;
  };

  const priorityCases = state.cases.sort(compareStages);

  return (
    <Row className="myCasesListRow" align="top">
      <Col span={24} className="prioritySection">
        {/* Priority  */}
        <Row>
          <Col span={24} style={{ padding: '5px 10px', background: '#f3f5fd' }}>
            <h4 style={{ color: '#123ee0' }}>Priority</h4>
          </Col>
          <Col span={24} style={{}}>
            <Row justify="space-between">
              {priorityCases.map((item: DbRecordEntityTransform, index: number) => (
                <MyCasesListItem Case={item} index={index} schema={schema} />
              ))}
            </Row>
          </Col>
        </Row>

        {/* Other */}
        {/*<Row>*/}
        {/*  <Col span={24} style={{ padding: '5px 10px', background: '#F8F8F8' }}>*/}
        {/*    <h4 style={{ color: 'black' }}>Other</h4>*/}
        {/*  </Col>*/}
        {/*  <Col span={24}>*/}
        {/*    <Row justify="space-between">*/}
        {/*      {otherCases.map((item: DbRecordEntityTransform, index: number) => (*/}
        {/*        <MyCasesListItem Case={item} index={index} />*/}
        {/*      ))}*/}
        {/*    </Row>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Col>
    </Row>
  );
};

export default MyCasesList;
